import React, { Fragment } from "react";
import ProductCard from "./ProductCard";
import { Row } from "reactstrap";
import { useRecoilValue } from "recoil";
import {
  productsSelector,
  productsSelectorBySection,
} from "../../../../recoil-atoms/webSiteAtoms";
import { createUseStyles } from "react-jss";
import { _Colors } from "../../../../Jss-styles/_variables";

const useStyles = createUseStyles({
  Container: {
    fontSize: "1rem",
    lineHeight: "1.5",
    display: "flex",
    flexWrap: "wrap",
    rowGap: "1.5rem",
    columnGap: "1.5rem",
    justifyContent: "center",
    marginInline: "1em",
  },
});

const PricingCards = ({ cursus, section = null, pays = null }) => {
  const classes = useStyles();
  const products = useRecoilValue(
    section === null
      ? productsSelector(cursus)
      : productsSelectorBySection(section)
  );
  const lesCursus = ["classique", "double", "bilingue", "belge"];
  let produitsCursusSegmentes = [];

  const couleurs = {
    classique: _Colors.blue,
    double: _Colors.purple,
    bilingue: _Colors.violet,
    belge: _Colors.pink,
  };

  const couleur = couleurs[`${cursus}`];

  if (lesCursus.includes(cursus)) {
    const segmenterProduitsCursus = () => {
      const produitsSegmentes = [];
      const segmentsFrance = [
        ["Maternelle"],
        ["CP", "CE1", "CE2"],
        ["CM1", "CM2", "6eme"],
        ["5eme", "4eme"],
        ["3eme"],
        ["Seconde"],
        ["Tronc Commun"],
        ["Première", "Terminale"],
      ];

      const segmentsBelgique = [
        ["1re année", "2e année"],
        ["3e année", "4e année"],
        ["5e année", "6e année"],
      ];

      const segments =
        cursus === "belge" || pays === "Belgique"
          ? segmentsBelgique
          : segmentsFrance;
      segments.forEach((segment) => {
        const array = products.filter((product) =>
          segment.includes(product.name)
        );
        if (array.length !== 0) {
          produitsSegmentes.push(array);
        }
      });
      return produitsSegmentes;
    };

    produitsCursusSegmentes = segmenterProduitsCursus();
  }

  function getProductsCardsByCategory() {
    //récupérer les produits ayant l'attribut category (cad les PRODUITS A LA CARTE SANS LE TRONC COMMUN)
    let coursExclusifs = [];
    let madrassaAuthentique = [];
    let stages = [];
    let ramadan = [];

    const madrassaOrder = [
      "Formule Nouraniya",
      "Formule Arabe",
      "Arabe Intensif",
      "Formule Coran",
      "Coran Intensif",
      "Sciences Religieuses",
      "Formule Complète",
      //"Formule Intensive",
    ];

    const StageOrder = [
      "Stage essentiel",
      "Stage bénéfique",
      "Stage de langues",
    ];

    products.forEach((pdt) => {
      if (pdt.category) {
        if (pdt.category === "ramadan") {
          ramadan.push(pdt);
        }
        if (pdt.category === "exclusif") {
          coursExclusifs.push(pdt);
        }
        if (pdt.category === "pole") {
          madrassaAuthentique[madrassaOrder.indexOf(pdt.name)] = pdt;
        }
        if (pdt.category === "stage") {
          stages[StageOrder.indexOf(pdt.name)] = pdt;
        }
      }
    });

    return (
      <Fragment>
        {ramadan.length !== 0 && (
          <>
            <h3 style={{ marginTop: "2em" }}>
              <span role="img" aria-label="etoiles">
                ✨
              </span>
               SPÉCIAL RAMADAN 2025 : session d'apprentissage du Coran pour vos
              enfants
            </h3>
            <Row
              className="justify-content-center mb-5"
              style={{ columnGap: "1.5em", rowGap: "1.5em" }}
            >
              {ramadan.map((p, k) => {
                return (
                  <ProductCard key={k} segment={[p]} color={_Colors.darkPink} />
                );
              })}
            </Row>
          </>
        )}
        {coursExclusifs.length !== 0 && (
          <>
            <h3>
              Cours particuliers : étudiez une matière avec le soutien d'un
              professeur particulier
            </h3>
            <Row
              className="justify-content-center mb-5"
              style={{ columnGap: "1.5em", rowGap: "1.5em" }}
            >
              {coursExclusifs.map((p, k) => {
                return (
                  <ProductCard key={k} segment={[p]} color={_Colors.darkPink} />
                );
              })}
            </Row>
          </>
        )}

        {madrassaAuthentique.length !== 0 && (
          <>
            <h3>
              La Madrassa Authentique : pour vos enfants, en petits groupes, un
              programme d'arabe et de religion de qualité
            </h3>
            <Row
              className="justify-content-center mb-5"
              style={{ columnGap: "1.5em", rowGap: "1.5em" }}
            >
              {madrassaAuthentique.map((p, k) => {
                return (
                  <ProductCard
                    key={k}
                    segment={[p]}
                    color={_Colors.darkPink}
                    //section={section}
                  />
                );
              })}
            </Row>
          </>
        )}

        {stages.length !== 0 && (
          <>
            <h3 id="tarifs-stages-ete">
              Le stage d'été : un indispensable pour ancrer ses connaissances ou
              approfondir avant la rentrée
            </h3>
            <h4 style={{ color: _Colors.darkPink }}>
              Session 2025 : du lundi 7 juillet au jeudi 31 juillet 2025 (4
              semaines)
            </h4>
            <Row
              className="justify-content-center mb-5"
              style={{ columnGap: "1.5em", rowGap: "1.5em" }}
            >
              {stages.map((p, k) => {
                return (
                  <ProductCard key={k} segment={[p]} color={_Colors.darkPink} />
                );
              })}
            </Row>
          </>
        )}
      </Fragment>
    );
  }

  return (
    <>
      <div className={classes.Container}>
        {/*produits cursus */}
        {lesCursus.includes(cursus) &&
          produitsCursusSegmentes.map((segment, k) => {
            return (
              <ProductCard
                key={k}
                segment={segment}
                color={couleur}
                pays={pays}
              />
            );
          })}
        {/*tous les produits à la carte */}
        {cursus === "carte" && getProductsCardsByCategory()}
        {/* ateliers */}
        {cursus === "atelier" && (
          <>
            <h3 style={{ marginTop: "2em" }}>
              Ateliers récréactifs : ludiques et interactifs pour atteindre
              l'excellence
            </h3>
            <Row
              className="justify-content-center mb-5"
              style={{ columnGap: "1.5em", rowGap: "1.5em" }}
            >
              {products.map((p, k) => {
                return (
                  <ProductCard
                    key={k}
                    segment={[p]}
                    color={_Colors.darkGreen}
                  />
                );
              })}
            </Row>
          </>
        )}
        {/* pec */}
        {cursus === "pec" && (
          <>
            <h3 style={{ marginTop: "2em" }}>
              Pack d'Enseignement par Correspondance : évoluez à votre rythme
              tout en autonomie
            </h3>
            <Row
              className="justify-content-center mb-5"
              style={{ columnGap: "1.5em", rowGap: "1.5em" }}
            >
              {products.map((p, k) => {
                return (
                  <ProductCard key={k} segment={[p]} color={_Colors.orange} />
                );
              })}
            </Row>
          </>
        )}

        {/*PAGE Madrassa Authentique */}
        {section && section.includes("madrassa") && (
          <>
            {products.map((p, k) => {
              return (
                <ProductCard key={k} segment={[p]} color={_Colors.darkPink} />
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default PricingCards;
