import React, { Fragment, useState } from "react";
import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import ModalButton from "./ModalButton";
import { createUseStyles } from "react-jss";
import { _Colors, _BoxShadows } from "../../../../Jss-styles/_variables";
import clsx from "clsx";
import { FournirEcheancier } from "./Echeanciers";
import useWindowResize from "../../../Responsive";

const ProductCard = ({ segment, color, pays = null }) => {
  //1 segment = un groupe de produits de même tarif donc donnant lieu à une seule carte tarifaire
  const { width } = useWindowResize();
  const useStyles = createUseStyles({
    Card: {
      borderStyle: "none",
      borderRadius: "1.5625em !important", //25px
      boxShadow: _BoxShadows.medium,
      transition: "all 200ms ease-in",
      width: "28.125em", //450px
      "&:hover": {
        boxShadow: _BoxShadows.large,
      },
    },
    CardHeader: {
      color: "white",

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: color,
      padding: "0px",
      height: "3.5em",
      "& h2": {
        marginBottom: "0",

        textAlign: "center",
      },
      borderStyle: "none",
      borderTopLeftRadius: "1.5625em !important",
      borderTopRightRadius: "1.5625em !important",
    },

    CardBody: {
      padding: "0",
    },
    Cycle: {
      height: "1.875em", //30px
      borderBottom: `1px solid ${color}`,
      textAlign: "center",
      marginBottom: "1em",
      color: color,
    },
    FormulesContainer: {
      display: "flex",
      justifyContent: "center",
    },
    Formule: {
      color: color,
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      rowGap: "1em",
      justifyContent: "space-between",
      flexGrow: 1,
    },
    FormuleDetails: {
      paddingInline: "0.9em",
      display: "flex",
      flexDirection: "column",
      rowGap: "1em",
      "& i": {
        cursor: "pointer",
        transition: "0.5s",
        "&:hover": {
          transform: "scale(1.1)",
        },
      },
    },
    NotLastFormule: {
      borderRight: `1px solid ${color}`,
    },
    FormulePrix: {
      fontSize: "1.6875rem",
      fontWeight: "bold",
    },
    FormulePrixPromo: {
      extend: "FormulePrix",
      color: `${_Colors.darkGreen}`,
    },

    Economies0: {
      backgroundColor: `${_Colors.blue3}`,
      borderTop: `1px solid ${color}`,
    },
    Economies1: {
      backgroundColor: "#56E64C", //vert
      color: "white",
      fontWeight: "bold",
      "& del": {
        opacity: "0.7",
      },
    },
    Economies2: {
      backgroundColor: "#22D115", //vert foncé
      color: "white",
      fontWeight: "bold",
      "& del": {
        opacity: "0.7",
      },
    },

    cardBody: {
      color: color,
      textAlign: "center",
      padding: "0px",
      "& .month-price": {
        fontSize: "4.5rem",
        marginBottom: "0px",
        "& span": {
          fontSize: "1.5rem",
        },
      },
      "& .other-price": {
        fontSize: "2.25rem",
        fontWeight: "bold",
        "& span": {
          fontSize: "1.5rem",
          fontWeight: "normal",
        },
      },
      "& .duration": {
        fontSize: "1.5rem",
        fontWeight: "bold",
      },
      "& .course-text": {
        textAlign: "center",
        fontSize: "0.9rem",
        color: "black",
        marginBottom: "1rem",
        textTransform: "uppercase",
      },
    },

    CardFooter: {
      color: "white",
      backgroundColor: color,
      height: "3.5em",
      padding: "0px",
      borderStyle: "none",
      borderBottomLeftRadius: "25px !important",
      borderBottomRightRadius: "25px !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    Modal: {
      position: "fixed",
      top: "0",
      left: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.6)",
      zIndex: "999",
      "& img": {
        maxWidth: "350px",
        marginBottom: "1em",
      },
      "& > div": {
        position: "relative",
        overflowY: "auto", //la barre de scroll n'apparaitra que si nécessaire
        //cursor: "pointer",
        animation: "$appearAnimation 0.8s",

        backgroundColor: "white",
        maxWidth: "700px",
        maxHeight: "70vh",
        padding: "2em",
        margin: "1em",
        "& button": {
          display: "block",
          marginInline: "auto",
          padding: "0.25em",
          width: "10rem",
          backgroundColor: _Colors.darkGreen,
          border: "none",
          borderRadius: "0.2em",
          color: "white",
        },
      },
    },
    "@keyframes appearAnimation": {
      from: {
        opacity: 0,
        transform: "scale(0)",
      },
      to: {
        opacity: 1,
        transform: "scale(1)",
      },
    },
  });

  const classes = useStyles();

  let ConvertStringToHTML = function (str) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, "text/html");
    return doc.body.innerHTML;
  };

  const firstProduct = segment[0];
  let productInfos = ConvertStringToHTML(firstProduct.infos);
  const prixMensuel = firstProduct.price.month;
  const prixTrimestriel = firstProduct.price.trimester;
  const prixAnnuel = firstProduct.price.year;
  const price = firstProduct.price;

  const [modal, setModal] = useState(false);
  const [formule, setFormule] = useState("mensuel");
  const [echeancier, setEcheancier] = useState("");
  const [formuleEnglish, setFormuleEnglish] = useState("month");
  const [cm, setCm] = useState(10);

  const toggle = (e) => {
    setModal((prev) => !prev);
    const formuleF = e.currentTarget.dataset.formule;

    if (formuleF) {
      const formuleE =
        formuleF === "mensuel"
          ? "month"
          : formuleF === "trimestriel"
          ? "trimester"
          : formuleF === "annuel"
          ? "year"
          : "";
      setFormule(formuleF);

      setFormuleEnglish(formuleE);
      const CM =
        formuleF === "mensuel"
          ? 10
          : formuleF === "trimestriel"
          ? 3
          : formuleF === "annuel"
          ? 1
          : 0;
      setCm(CM);

      setEcheancier(
        FournirEcheancier(firstProduct.cursus, formuleF, firstProduct.name)
      );
    }
  };

  const affichageVolumeHoraire = (volume, category) => {
    const nbHeures = parseInt(volume);
    const nbMinutes = parseInt(60 * (volume - nbHeures));
    const stringMinutes = nbMinutes !== 0 ? `${nbMinutes} ` : "";
    const frequence = category !== "stage" ? "/ Semaine" : "/ Jour";
    return `${nbHeures} h ${stringMinutes}${frequence}`;
  };

  const lesCursus = ["classique", "double", "bilingue", "belge"];

  return (
    <Card className={classes.Card}>
      {/**HEADER : nom des produits du segment, séparés par un tiret*/}
      <CardHeader className={classes.CardHeader}>
        <h2>
          {segment.map((product, k) => {
            return (
              <Fragment key={`tarifs-carte-tarif-${k}`}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: product.abrev ? product.abrev : product.name,
                  }}
                ></span>
                {/**si le produit n'est pas le dernier du segment, on ajoute un tiret */}
                {segment[segment.length - 1] !== product && (
                  <span>{" - "}</span>
                )}
              </Fragment>
            );
          })}
        </h2>
      </CardHeader>
      {/* 2 CARDBODY : NOUVEAU POUR LES CURSUS + TC ET ANCIEN POUR LE RESTE*/}
      {lesCursus.includes(firstProduct.cursus) ||
      firstProduct.name === "Tronc Commun" ? (
        <CardBody className={classes.CardBody}>
          <div className={classes.Cycle}>{firstProduct.cycle}</div>
          <div className={classes.FormulesContainer}>
            {/*FORMULE MENSUELLE*/}
            <div className={classes.Formule}>
              <div
                className={clsx(classes.FormuleDetails, classes.NotLastFormule)}
              >
                <div>
                  mensuel{width < 425 ? <br /> : "  "}
                  <i
                    className="fa-regular fa-circle-question"
                    onClick={toggle}
                    data-formule="mensuel"
                  ></i>
                </div>
                <div className={classes.FormulePrix}>{prixMensuel} €</div>
                {/** PRIX PROMOTIONNEL
                {firstProduct.cursus === "double" && (
                  <div className={classes.FormulePrixPromo}>  </div>
                )}*/}
                <div>
                  soit au total
                  <br />
                  {prixMensuel * 10} €
                  <br /> 
                </div>
              </div>
              <div className={classes.Economies0}>
                {/**PRIX PROMOTIONNEL 
                {firstProduct.cursus === "double" ? (
                  <b>
                    TARIF
                    <br />
                    INITIAL
                  </b>
                ) : (*/}
                <b>tarif initial</b>
                {/** PRIX PROMOTIONNEL )}*/}
              </div>
            </div>
            {/*FORMULE TRIMESTRIELLE */}
            <div className={classes.Formule}>
              <div
                className={clsx(classes.FormuleDetails, classes.NotLastFormule)}
              >
                <div>
                  trimestriel{width < 425 ? <br /> : " "}
                  <i
                    className="fa-regular fa-circle-question"
                    onClick={toggle}
                    data-formule="trimestriel"
                  ></i>
                </div>
                <div className={classes.FormulePrix}>
                  {/** PRIX PROMOTIONNEL 
                  {firstProduct.cursus === "double" ? (
                    <del>{prixTrimestriel} €</del>
                  ) : (
                    <>*/}
                  {prixTrimestriel} €
                  {/*PRIX PROMOTIONNEL</>
                  )}*/}
                </div>
                {/**PRIX PROMOTIONNEL 
                {firstProduct.cursus === "double" && (
                  <div className={classes.FormulePrixPromo}>
                    {prixMensuel * 3} €
                  </div>
                )}*/}

                <div>
                  soit au total
                  <br />
                  {/**PRIX PROMOTIONNEL 
                  {firstProduct.cursus === "double" ? (
                    <>
                      <del>{prixTrimestriel * 3} €</del>
                      <br />
                      <span style={{ color: _Colors.darkGreen }}>
                        {prixMensuel * 9} €
                      </span>
                    </>
                  ) : (
                    <>*/}
                  {prixTrimestriel * 3} €
                  {/*PRIX PROMOTIONNEL</>
                  )}*/}
                </div>
              </div>
              <div className={classes.Economies1}>
                {/**PRIX PROMOTIONNEL 
                {firstProduct.cursus === "double" ? (
                  <>
                    <del>{prixMensuel * 10 - prixTrimestriel * 3} € d'éco.</del>
                    <br />
                    {prixMensuel} € d'éco.
                  </>
                ) : (
                  <>*/}
                {prixMensuel * 10 - prixTrimestriel * 3} € d'éco.
                {/*PRIX PROMOTIONNEL</>
                )}*/}
              </div>
            </div>
            {/*FORMULE ANNUELLE */}
            <div className={classes.Formule}>
              <div className={classes.FormuleDetails}>
                <div>
                  annuel{width < 425 ? <br /> : "  "}
                  <i
                    className="fa-regular fa-circle-question"
                    onClick={toggle}
                    data-formule="annuel"
                  ></i>
                </div>
                <div className={classes.FormulePrix}>
                  {/**PRIX PROMOTIONNEL 
                  {firstProduct.cursus === "double" ? (
                    <del>{prixAnnuel} €</del>
                  ) : (
                    <>*/}
                  {prixAnnuel} €
                  {/**PRIX PROMOTIONNEL </>
                  )}*/}
                </div>
                {/**PRIX PROMOTIONNEL 
                {firstProduct.cursus === "double" && (
                  <div className={classes.FormulePrixPromo}>
                    {Math.round(prixMensuel * 10 * 0.85)} €
                  </div>
                )}*/}
              </div>
              <div className={classes.Economies2}>
                {/**PRIX PROMOTIONNEL 
                {firstProduct.cursus === "double" ? (
                  <>
                    <del>{prixMensuel * 10 - prixAnnuel} € d'éco.</del>
                    <br />
                    {Math.round(prixMensuel * 10 * 0.15)} € d'éco.
                  </>
                ) : (
                  <>*/}
                {prixMensuel * 10 - prixAnnuel} € d'éco.
                {/**PRIX PROMOTIONNEL </>
                )}*/}
              </div>
            </div>
          </div>
        </CardBody>
      ) : (
        <CardBody
          className={`${classes.cardBody} d-flex flex-column justify-content-around py-3`}
        >
          {/**anciennes cartes -> non cursus */}
          {price.month !== undefined && (
            <p className={`month-price`}>
              {price.month} <span>&euro; / Mois (x 10)</span>
            </p>
          )}
          {price.trimester !== undefined && (
            <p className={`other-price`}>
              {" "}
              {price.trimester} <span>&euro; / Trimestre (x 3)</span>
            </p>
          )}
          {price.year !== undefined && (
            <p className={`other-price`}>
              {price.year} <span>&euro; / An (x 1)</span>
              {/**<span style={{ fontSize: "1rem" }}>
                <br />
                au lieu de {price.yearInitial} €
              </span>
              <span style={{ color: _Colors.pink, fontSize: "1rem" }}>
                {" "}
                (
                {Math.round(
                  (price.year - price.yearInitial) / (0.01 * price.yearInitial)
                )}
                 %)
              </span> */}
            </p>
          )}
          {price.thrice !== undefined && (
            <p className={`month-price`}>
              {" "}
              {price.thrice} 
              <span>
                &euro;
                {firstProduct.name !== "Formule Nouraniya"
                  ? " en 3x"
                  : " / Mois (x 3)"}
              </span>
            </p>
          )}
          {price.once !== undefined && (
            <p
              className={`month-price`}
              /*className={
                firstProduct.name === "Formule Nouraniya"
                  ? "other-price"
                  : `month-price`
              }*/
            >
              {price.once} <span>&euro;</span>
            </p>
          )}
          {price.twice !== undefined && (
            <p className={`other-price`}>
              {" "}
              {price.twice} <span>&euro; en 2x </span>
            </p>
          )}

          {price.hour !== undefined && (
            <p className={`month-price`}>
              {" "}
              {price.hour} <span>&euro; / Heure</span>
            </p>
          )}
          {/**stages */}
          {price.one !== undefined && (
            <p className={`month-price`}>
              {price.one} <span>&euro; &#x2192; 1 matière</span>
            </p>
          )}
          {price.two !== undefined && (
            <p className={`other-price`}>
              {price.two} <span>&euro; &#x2192; 2 matières</span>
            </p>
          )}
          {/*heures*/}
          {firstProduct.hours !== undefined && (
            <p className="duration">
              {affichageVolumeHoraire(
                parseFloat(firstProduct.hours),
                firstProduct.category
              )}
            </p>
          )}
          {firstProduct.infos !== undefined && (
            <p className="course-text">
              <span dangerouslySetInnerHTML={{ __html: productInfos }} />
            </p>
          )}
        </CardBody>
      )}

      <CardFooter className={classes.CardFooter}>
        <ModalButton segment={segment} />
      </CardFooter>
      {modal && (
        <div className={classes.Modal} onClick={toggle}>
          <div>
            <h5>Qu'est-ce que le paiement {formule} ?</h5>
            <p>
              {formule !== "mensuel" && (
                <>
                  Grâce au paiement {formule}, vous réalisez une économie de{" "}
                  {formule === "trimestriel" ? "5 %" : "10 %"} par rapport au
                  tarif initial.{" "}
                </>
              )}
              {formule !== "annuel" && (
                <>
                  Il représente une facilité de paiement du montant total{" "}
                  <b>{formule === "mensuel" ? "en 10 fois" : "en 3 fois"}</b>,
                  et non le paiement d'un seul{" "}
                  {formule === "mensuel" ? "mois" : "trimestre"}.
                </>
              )}
            </p>

            <h5>Comment se déroulera mon paiement {formule} ?</h5>
            <img src={echeancier} alt="echéancier paiement"></img>
            {/** 
            <p>
              La totalité des échéances est redevable quel que soit le moment de
              l'inscription.
              <br />
              Ex : Pour une inscription au mois d'octobre, les échéances d'août
              et de septembre seront tout aussi dues.
            </p>*/}
            {firstProduct.name !== "Tronc Commun" && (
              <>
                <p>
                  Premier versement = acompte (100 €) + frais de dossier (39 €).
                </p>
                <p>
                  Total = prix du produit (
                  {firstProduct.price[formuleEnglish] * cm} €) + frais de
                  dossier (39 €)
                </p>
                <p>
                  (1) L'acompte versé aujourd'hui et les réductions seront
                  déduits des dernières échéances.
                </p>
              </>
            )}

            {formule !== "annuel" && (
              <p>
                (2) À partir de janvier 2025, la TVA s'appliquera aux factures à
                destination des pays européens concernés.{" "}
                <a href="/documents/taux_TVA_Union_Européenne.pdf">
                  Voir la liste
                </a>
              </p>
            )}

            <button>OK</button>
          </div>
        </div>
      )}
    </Card>
  );
};

export default ProductCard;
