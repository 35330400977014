import React, { useEffect, useState } from "react";
import { useStyles } from "../StyleCursus";
import useWindowResize from "../../../Responsive";
import { _Colors } from "../../../../Jss-styles/_variables";
import ModalBook from "../../Supports/components/ModalBook";
import Schedules from "../Schedules/Schedules";
import { schedulesConfig } from "../Schedules/schedules.config";
import FormCodePromo from "../FormCodePromo";
import PricingCards from "../../Prices/components/PricingCards";

//images
import banniere from "../../../../assets/img/cursus/double-cursus/banniere-200h.webp";
import puce from "../../../../assets/img/puce.svg";
import offreCC from "../../../../assets/img/cursus/offre-cursus-classique.png";
import offreCBI from "../../../../assets/img/cursus/offre-cursus-bilingue.png";
import clipartGarantie from "../../../../assets/img/clipart-garantie.png";
import clock from "../../../../assets/img/cursus/clock.webp";
import lettreArabe from "../../../../assets/img/cursus/double-cursus/lettre_alphabet.png";
import avatar from "../../../../assets/img/avatar-classe.svg";
import lettresSortentCrayon from "../../../../assets/img/cursus/double-cursus/crayon_arabe.jpeg";
import un from "../../../../assets/img/cursus/double-cursus/lycee/ecrire_arabe_garcons.jpeg";
import deux from "../../../../assets/img/cursus/double-cursus/lycee/lecture_coran_lycee.jpeg";
import trois from "../../../../assets/img/cursus/double-cursus/lycee/ordi_lycee.jpeg";
import coran from "../../../../assets/img/cursus/double-cursus/coran.jpeg";

//supports
import supportArabeBleu from "../../../../assets/pdf/supports/double-cursus/arabe/arabe_niveau_bleu.pdf";
import supportArabeRouge from "../../../../assets/pdf/supports/double-cursus/arabe/arabe_niveau_rouge.pdf";
import supportArabeVert from "../../../../assets/pdf/supports/double-cursus/arabe/arabe_niveau_vert.pdf";
import supportArabeTurquoise from "../../../../assets/pdf/supports/double-cursus/arabe/arabe_niveau_turquoise.pdf";
import supportFiqhJaune from "../../../../assets/pdf/supports/double-cursus/fiqh/fiqh_niveau_jaune.pdf";
import supportTajwidJaune from "../../../../assets/pdf/supports/double-cursus/tajwid/tajwid_niveau_jaune.pdf";
import supportTajwidMarron from "../../../../assets/pdf/supports/double-cursus/tajwid/tajwid_niveau_marron.pdf";
import supportTajwidTurquoise from "../../../../assets/pdf/supports/double-cursus/tajwid/tajwid_niveau_turquoise.pdf";
import supportCroyanceBleu from "../../../../assets/pdf/supports/double-cursus/croyance/croyance_niveau_bleu.pdf";
import supportCroyanceVert from "../../../../assets/pdf/supports/double-cursus/croyance/croyance_niveau_vert.pdf";
import supportCroyanceMarron from "../../../../assets/pdf/supports/double-cursus/croyance/croyance_niveau_marron.pdf";

const LyceeDouble2024 = ({ setSelectedNavItem, niveau }) => {
  const classes = useStyles();
  const { width } = useWindowResize();
  const [appareil, setAppareil] = useState();
  const [modalForm, setModalForm] = useState(false);

  useEffect(() => {
    setSelectedNavItem(1);
    // eslint-disable-next-line
  }, []);

  const toggleFormulaire = () => {
    setModalForm((prev) => !prev);
  };

  const responsiveWidth = {
    video: {
      PC: 656,
      Mobile: width * 0.9,
      Tablette: width * 0.9,
    },
    avatar: {
      PC: 48,
      Mobile: 36,
      Tablette: 36,
    },
    horloge: {
      PC: 32,
      Mobile: 28,
      Tablette: 28,
    },
    illustration: {
      PC: 400,
      Mobile: width * 0.9,
      Tablette: width * 0.63,
    },
  };
  const responsiveHeight = {
    video: {
      PC: 369,
      Mobile: (width * 0.9 * 9) / 16,
      Tablette: (width * 0.9 * 9) / 16,
    },
    avatar: {
      PC: 48,
      Mobile: 36,
      Tablette: 36,
    },
    horloge: {
      PC: 32,
      Mobile: 28,
      Tablette: 28,
    },
  };

  const format = () => {
    if (width >= 1366) {
      return "PC";
    } else if (width > 600) {
      return "Tablette";
    } else {
      return "Mobile";
    }
  };

  useEffect(() => {
    setAppareil(format());
    // eslint-disable-next-line
  }, [width]);

  const levels = [
    "Seconde",
    "Première Générale",
    "Première STMG",
    "Première ST2S",
    //"Première STI2D",
    "Terminale Générale",
    "Terminale STMG",
    "Terminale ST2S",
  ];
  const [level, setLevel] = useState(niveau ? niveau : levels[0]);
  const matieres = ["Arabe", "Croyance", "Fiqh", "Tajwid"];
  const [matiere, setMatiere] = useState(matieres[0]);
  const edtBtnWidth = width <= 1624 ? "500px" : "70%";
  const supports = {
    Arabe: [
      {
        title: "NIV. BLEU",
        src: supportArabeBleu,
        numPages: 20,
      },
      {
        title: "NIV. VERT",
        src: supportArabeVert,
        numPages: 10,
      },
      {
        title: "NIV. ROUGE",
        src: supportArabeRouge,
        numPages: 13,
      },
      {
        title: "NIV. TURQUOISE",
        src: supportArabeTurquoise,
        numPages: 26,
      },
    ],
    Croyance: [
      {
        title: "NIV. BLEU",
        src: supportCroyanceBleu,
        numPages: 15,
      },
      {
        title: "NIV. VERT",
        src: supportCroyanceVert,
        numPages: 10,
      },
      {
        title: "NIV. MARRON",
        src: supportCroyanceMarron,
        numPages: 20,
      },
    ],
    Fiqh: [
      {
        title: "NIV. JAUNE",
        src: supportFiqhJaune,
        numPages: 17,
      },
    ],
    Tajwid: [
      {
        title: "NIV. JAUNE",
        src: supportTajwidJaune,
        numPages: 13,
      },
      {
        title: "NIV. MARRON",
        src: supportTajwidMarron,
        numPages: 17,
      },
      {
        title: "NIV. TURQUOISE",
        src: supportTajwidTurquoise,
        numPages: 7,
      },
    ],
  };
  const infosLevels = {
    Seconde: {
      abrev: "2<sup>de</sup>",
      heures: "30 h",
      studentNb: "12",
      type: "non mixte",
      edt: schedulesConfig.highDC.Seconde,
      //supports: supports.Lycee,
    },
    "Première Générale": {
      abrev: "1<sup>re</sup> G",
      heures: "34,5 h",
      studentNb: "12",
      type: "non mixte",
      edt: schedulesConfig.highDC.PremiereG,
      //supports: supports.Lycee,
    },
    "Première STMG": {
      abrev: "1<sup>re</sup> STMG",
      heures: "30 h",
      studentNb: "12",
      type: "non mixte",
      edt: schedulesConfig.highDC.PremiereSTMG,
      //supports: supports.Lycee,
    },
    "Première ST2S": {
      abrev: "1<sup>re</sup> ST2S",
      heures: "33,5 h",
      studentNb: "12",
      type: "non mixte",
      edt: schedulesConfig.highDC.PremiereST2S,
      //supports: supports.Lycee,
    } /*
    "Première STI2D": {
      abrev: "1<sup>re</sup> STI2D",
      heures: "33 h",
      studentNb: "12",
      type: "non mixte",
      edt: schedulesConfig.highDC.PremiereSTI2D,
      //supports: supports.Lycee,
    },*/,
    "Terminale Générale": {
      abrev: "T<sup>le</sup> G",
      heures: "30,5 h",
      studentNb: "12",
      type: "non mixte",
      edt: schedulesConfig.highDC.TerminaleG,
      //supports: supports.Lycee,
    },
    "Terminale STMG": {
      abrev: "T<sup>le</sup> STMG",
      heures: "34,5 h",
      studentNb: "12",
      type: "non mixte",
      edt: schedulesConfig.highDC.TerminaleSTMG,
      //supports: supports.Lycee,
    },
    "Terminale ST2S": {
      abrev: "T<sup>le</sup> ST2S",
      heures: "32 h",
      studentNb: "12",
      type: "non mixte",
      edt: schedulesConfig.highDC.TerminaleST2S,
      //supports: supports.Lycee,
    },
  };

  return (
    <div className={classes.PageCursus}>
      {/**banniere */}
      <div
        className={classes.Banniere}
        style={{ backgroundColor: _Colors.purple }}
      >
        <img src={banniere} alt="banniere double cursus" />
      </div>
      <div className={classes.PageWrapper}>
        {/** titre */}
        <h1 className={classes.H1}>
          <div>DOUBLE CURSUS LYCÉE</div>
          <div>
            Le cursus classique combiné à l'apprentissage quotidien de l'arabe
            et de la religion
          </div>
        </h1>

        {/** vidéo + promesse */}
        <div className={classes.VideoEtPromesse}>
          <iframe
            width={responsiveWidth.video[`${appareil}`]}
            height={responsiveHeight.video[`${appareil}`]}
            src="https://www.youtube.com/embed/kU7XHH466KA?autoplay=1&mute=1&loop=1&playlist=kU7XHH466KA"
            title="Le Double Cursus Eve Éducation"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          {/**TPE3CQvWN2E */}
          <div className={classes.Promesse}>
            <div className={classes.TxtPromesse}>
              Une harmonie parfaite entre le système français et l'authenticité
              saoudienne pour un apprentissage enrichi de l'arabe et de l'islam
              {/*
              L'excellence française et la richesse saoudienne sont alliées pour
              offrir à nos enfants une instruction de premier choix dans les
              matières classiques, en langue arabe et en sciences religieuses.*/}
            </div>
            <a href="#tarifs-lycee-double">
              <button className={classes.BtnPromesseDouble}>
                J'inscris mon enfant
              </button>
            </a>
          </div>
        </div>

        {/** accroche */}
        <div
          className={classes.Accroche}
          style={{ backgroundColor: `${_Colors.purple2}` }}
        >
          {/*<strong>
            Optez pour un enseignement de qualité qui respecte vos valeurs
            {appareil === "PC" ? <br /> : " "}
            et accorde de l'attention aux capacités de votre enfant comme à ses
            difficultés !
          </strong>*/}
          <strong>
            Exceller dans ses études et vivre sa foi pleinement dans un
            environnement bienveillant
          </strong>
        </div>

        <div className={classes.InformationsCursus}>
          {/** COLONNE GAUCHE : emploi du temps */}
          <div className={classes.ColonnesGauchesContainer}>
            <div
              className={classes.ColonneGauche}
              style={{ backgroundColor: _Colors.purple2 }}
            >
              {/*Emplois du temps + supports*/}
              <div>
                <h2 className={classes.H2}>Emploi du temps {level}</h2>
                <div className={classes.Indications}>
                  <div className={classes.Indication}>
                    <img
                      src={avatar}
                      alt="clipart avatar"
                      width={responsiveWidth.avatar[`${appareil}`]}
                      height={responsiveHeight.avatar[`${appareil}`]}
                    ></img>
                      Classe : {infosLevels[level]["studentNb"]} élèves
                  </div>
                  <div className={classes.Indication}>
                    <img
                      src={clock}
                      alt="clipart heure"
                      width={responsiveWidth.horloge[`${appareil}`]}
                      height={responsiveWidth.horloge[`${appareil}`]}
                    ></img>
                      Volume horaire : {infosLevels[level]["heures"]} / semaine
                  </div>
                </div>
                <p>
                  <strong>Les cours ont lieu matin et après-midi</strong>{" "}
                </p>
                <p style={{ fontSize: "1rem", lineHeight: "1.8rem" }}>
                  Notre emploi du temps favorise une meilleure organisation
                  familiale et facilite l'intégration d'activités éducatives ou
                  sportives extrascolaires.
                </p>

                <div className={classes.LevelsBar}>
                  {levels.map((niveau, key) => {
                    return (
                      <div
                        key={key}
                        className={classes.LevelBtn}
                        style={
                          niveau === level
                            ? { backgroundColor: _Colors.violet2 }
                            : { backgroundColor: "white" }
                        }
                        onClick={() => setLevel(niveau)}
                        dangerouslySetInnerHTML={{
                          __html: infosLevels[niveau].abrev,
                        }}
                      ></div>
                    );
                  })}
                </div>
                <p style={{ fontSize: "1rem", lineHeight: "1.8rem" }}>
                  Choisissez la classe pour faire varier les informations
                </p>
                <div className={classes.FlexEdtSupports}>
                  <div className={classes.Edt}>
                    <Schedules
                      schedules={infosLevels[level].edt}
                      btnWidth={edtBtnWidth}
                    />
                    <small style={{ fontSize: "1rem", lineHeight: "1.8rem" }}>
                      Cliquer sur l'emploi du temps pour agrandir
                    </small>
                  </div>

                  <div className={classes.Supports}>
                    <h2 className={classes.H2}>Aperçu de nos supports</h2>
                    <div className={classes.LevelsBar}>
                      {matieres.map((mat, key) => {
                        return (
                          <div
                            key={key}
                            className={classes.LevelBtn}
                            style={
                              mat === matiere
                                ? { backgroundColor: _Colors.violet2 }
                                : { backgroundColor: "white" }
                            }
                            onClick={() => setMatiere(mat)}
                          >
                            {mat}
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      {supports[matiere].map((support, key) => {
                        return (
                          <ModalBook
                            key={key}
                            title={support.title}
                            src={support.src}
                            numPages={support.numPages}
                            backgroundColor={_Colors.purple}
                            style={classes.BtnSupports}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {appareil === "PC" && (
              <div className={classes.ColonneGauche2}>
                {/*photos*/}
                <img src={deux} alt="jeune homme lit le Coran"></img>
                <img
                  src={trois}
                  alt="deux lycéennes musulmanes cours en ligne"
                ></img>
                <img src={un} alt="deux garçons écrivent l'arabe"></img>

                <img
                  src={lettresSortentCrayon}
                  alt="lettres arabes sortent d'un crayon"
                ></img>
              </div>
            )}
          </div>

          {/*COLONNE DROITE */}
          <div className={classes.ColonneDroite}>
            {/** présentation cursus */}
            <h2 className={classes.H2}>Qu'est-ce que le Double cursus ?</h2>
            <p>
              C’est une richesse linguistique et religieuse qui offre à votre
              enfant{" "}
              <strong>
                la maîtrise de la langue du Coran (la langue arabe), la
                compréhension de ses mots (le tafsir) et sa récitation tout en
                respectant les règles de tadjwid
              </strong>
              .
            </p>
            <p>
              Ce cursus offre également à votre enfant{" "}
              <strong>
                l'apprentissage et la bonne compréhension de sa religion
              </strong>{" "}
              selon la sounnah du Prophète{" "}
              <span style={{ fontSize: "1.5rem" }}>&#65018;</span> et la
              compréhension des pieux prédécesseurs <span>رضي الله عنهم</span>.
            </p>
            <p>
              L’acquis de toute cette richesse se base sur{" "}
              <strong>
                des méthodologies ludiques, créatives, structurées et amusantes
              </strong>{" "}
              tout en respectant l’âge de votre enfant.
            </p>
            <p>
              Le Double cursus est une véritable opportunité pour vos enfants !
            </p>

            <div className={classes.PetiteAccroche}>
              <strong>
                Ensemble vers l'excellence éducative et une pratique religieuse
                authentique
              </strong>
            </div>
            <img
              className={classes.Illustration}
              src={coran}
              width={responsiveWidth.illustration[`${appareil}`]}
              alt="coran"
            ></img>
            {/**PROMO 
            <button
              className={classes.BtnPromoDouble}
              onClick={toggleFormulaire}
              style={{ marginBlock: "2em" }}
            >
              Je veux obtenir le code promo
            </button>*/}
            {/** objectifs  */}
            <h2 className={classes.H2}>
              Quels sont les objectifs du Double cursus au lycée ?
            </h2>
            <ul className={classes.Liste}>
              <li>
                <img src={puce} alt="puce" width="19"></img>
                <div>La maîtrise de la langue arabe : parlé et écrit</div>
              </li>

              <li>
                <img src={puce} alt="puce" width="19"></img>
                <div>La mémorisation du Coran avec les règles de tadjwid</div>
              </li>
              <li>
                <img src={puce} alt="puce" width="19"></img>
                <div>
                  La compréhension du sens des sourates acquises : tafsir
                </div>
              </li>
              <li>
                <img src={puce} alt="puce" width="19"></img>
                <div>
                  L'apprentissage de la science religieuse : aqidah, fiqh et
                  adhkar
                </div>
              </li>
            </ul>

            {/** programme  */}
            <h2 className={classes.H2} style={{ marginTop: "3em" }}>
              Quel est le programme du Double cursus au lycée ?
            </h2>

            <h3 className={classes.H3} style={{ marginTop: "28px" }}>
              <img src={lettreArabe}></img> Le programme de langue arabe
            </h3>
            <p>
              Il est élaboré sous la surveillance de notre équipe pédagogique
              par des enseignants diplômés et expérimentés dans ce domaine.
              C'est un programme adapté à nos élèves avec{" "}
              <strong>
                des méthodes d’apprentissage ludiques et créatives
              </strong>{" "}
              au sein des classes, que vous retrouvez dans nos propres supports
              pour chaque niveau.
            </p>
            <h3 className={classes.H3} style={{ marginTop: "28px" }}>
              <span role="img" aria-label="livre">
                📖
              </span>{" "}
              Le programme de Coran
            </h3>
            <p>
              Il comporte <strong>la mémorisation</strong> (selon plusieurs
              récitations - riwaya : Hafs, Warch, Qaloun),{" "}
              <strong>la compréhension et l’étude des regles de tadjwid</strong>
              .
            </p>
            <p>
              Nous proposons un programme complet du niveau débutant avec
              l'apprentissage de sourate an-Nass, jusqu'au lycée avec
              l'apprentissage de sourate al-Baqarah et du poème de{" "}
              <i>Tuhfat al-atfal</i> en tadjwid.
            </p>
            <h3 className={classes.H3} style={{ marginTop: "28px" }}>
              <span role="img" aria-label="mosquée">
                🕌
              </span>{" "}
              Le programme de sciences religieuses
            </h3>
            <p>
              Il est également élaboré sous la surveillance de notre équipe
              pédagogique par des enseignants diplômés, en se basant sur{" "}
              <strong>
                le programme de l'Éducation nationale de l'Arabie Saoudite
              </strong>
              , élaboré et validé par le ministère des affaires religieuses
              présidé par Sheikh Salih Al Sheikh.
            </p>
            {/**PROMO 
            <button
              className={classes.BtnPromoDouble}
              onClick={toggleFormulaire}
              style={{ marginBlock: "2em" }}
            >
              Je veux obtenir le code promo
            </button>*/}
            {/** admissions  */}
            <h2 className={classes.H2} style={{ marginTop: "3em" }}>
              Quelles sont les conditions d'admission ?
            </h2>
            <p className={classes.ApresParagraphe}>
              <strong>Tout le monde peut s'inscrire, sans conditions</strong>.
              Nous évaluerons, après inscription, le niveau de votre enfant afin
              de l'inclure dans une classe adaptée à ses résultats. Cette
              approche sur mesure nous permet de répondre aux besoins et
              capacités uniques de chaque élève afin qu'il s'épanouisse dans sa
              future classe.
            </p>
            {/** déroulement  */}
            <h2 className={classes.H2}>Comment se déroulent les cours ?</h2>
            <p>
              Comme tous les cursus chez EVE Éducation, les cours s'effectuent{" "}
              <strong>
                en visio, au sein d'une classe interactive à effectif réduit
              </strong>{" "}
              pour un apprentissage optimal (environ 12 élèves).
              {/*<br />
              En savoir plus sur <a href="/">nos enseignants en primaire</a>.*/}
              <br />
              Les cours ont lieu <strong>du dimanche au jeudi</strong> (matin et
              après-midi) pour un volume journalier de 6 à 7 heures.
              <br />
              <strong>Les classes sont séparées filles/garçons</strong>.
            </p>
            <p>
              Tout au long de l’année, l'élève bénéficie d’un accompagnement
              particulier qui répond à ses besoins.{" "}
              <strong>
                Il bénéficie aussi d’un semainier complet pour lui permettre de
                travailler régulièrement les notions vues en classe
              </strong>
              . Des examens réguliers valident ses compétences.
            </p>
            <p className={classes.ApresParagraphe}>
              À la fin de chaque passage, nous offrons à nos élèves des{" "}
              <strong>certificats de mérite</strong> pour l’effort fourni.
            </p>
            {/** zoom  */}
            <h2 className={classes.H2}>Venez discuter avec nous</h2>
            <p>
              Vous souhaitez en savoir plus et dialoguer avec nous avant de vous
              décider ?
              <br />
              Une permanence sur Zoom est accessible{" "}
              <strong>chaque vendredi matin de 9 h à 11 h</strong>.<br />
              Nous accueillons les familles pour un échange privilégié, par
              ordre d'arrivée.
            </p>
            <a
              href="https://us06web.zoom.us/my/salle75"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className={classes.BtnZoom}>
                Nous rejoindre sur Zoom
              </button>
            </a>
          </div>
        </div>
        {/** preuve sociale  */}

        <div
          className={classes.PreuveSociale}
          style={{ flexDirection: "column", backgroundColor: _Colors.purple2 }}
          id="temoignages-dc-lycee"
        >
          <h2
            className={classes.H2}
            style={
              appareil === "PC"
                ? { marginBottom: "2em" }
                : { marginBottom: "0" }
            }
          >
            Témoignages de parents d'élèves
          </h2>
          <div className={classes.TemoignagesContainer}>
            <blockquote className={classes.Temoignage}>
              <i
                className="fa-solid fa-quote-left"
                style={{ color: _Colors.purple, fontSize: "2rem" }}
              ></i>
               Mon expérience avec Eve education est très riche par la grâce
              d'Allah puis leur pédagogie et leur bienveillance qui permet de
              toujours évoluer maachAllah. J'aimerais parler du double cursus
              qui a été pour moi une grande joie et une grande satisfaction au
              delà de mes attentes maachAllah, surtout que la langue arabe, le
              Coran, et les sciences telles que le tewhid, la jurisprudence etc
              sont des clés essentielles pour la compréhension de l'islam. Ma
              première surprise, c'est le niveau et la pédagogie de
              l'enseignante en langue arabe, methode ordonnée et progressive. Le
              Coran avec une enseignante diplômée d'une ijeza (maitrise de la
              lecture du Coran qui permet l'enseignement du Coran) dans
              plusieurs lectures du Coran, avec l'enseignement des règles de
              tejwid (règles de psalmodie) et le tefsir (l'explication du
              Coran). Ma seconde surprise c'est que mon enfant a fini le Coran
              avec son enseignante Allahouma baarik fiihima, et elle revise tout
              en apprenant une nouvelle lecture. Et la troisième surprise c'est
              le niveau et le programme de science religieuse qui n'est basé que
              sur les livres et la méthode des grands savants de l'Islam. Voilà
              l'aventure Eve education toujours des surprises et des prouesses
              au delà de nos attentes maachAllah. Nous remercions Allah de nous
              avoir guidé dans nos choix et bonne continuation à cette école
              avec beaucoup de réussite. 
              <i
                className="fa-solid fa-quote-right"
                style={{ color: _Colors.purple, fontSize: "2rem" }}
              ></i>
              <footer>
                — <cite>Maman d'une élève (niveau avancé)</cite>
              </footer>
            </blockquote>
          </div>
        </div>

        {/** tarifs  */}
        <h2
          className={classes.H2Tarifs}
          id="tarifs-lycee-double"
          style={{ color: _Colors.purple, marginTop: "3em" }}
        >
          LES TARIFS
        </h2>
        <div className={classes.DivTarifs}>
          <div>
            Inscrivez <u>dès maintenant</u> votre enfant pour l'année 2025-2026
          </div>
          <div>
            Aujourd'hui, réglez <span>seulement 139 € HT</span> sur la totalité
            pour assurer votre place !
          </div>
          <div>Places limitées !</div>
        </div>
        <svg
          className={classes.FlechePromoDouble}
          xmlns="http://www.w3.org/2000/svg"
          width="76"
          height="108"
          viewBox="0 0 76 108"
        >
          <defs>
            <style>{`.a{fill:#F2E0F3;}`}</style>
          </defs>
          <g transform="translate(-922 -6623)">
            <rect
              className="a"
              width="46"
              height="9"
              transform="translate(937 6623)"
            />
            <rect
              className="a"
              width="46"
              height="47"
              transform="translate(937 6642)"
            />
            <path
              className="a"
              d="M38,0,76,49H0Z"
              transform="translate(998 6731) rotate(180)"
            />
          </g>
        </svg>

        {/** promos  */}
        {/** 
        <h2 className={classes.H2Promo} style={{ color: _Colors.purple }}>
          PROMOTION FÉVRIER EXCEPTIONNELLE À SAISIR D'URGENCE !
        </h2>
        <div className={classes.DivPromoDouble}>
          {appareil !== "Mobile" ? (
            <>
              <div>
                <span>Du 1er au 29 février</span>, profitez des meilleurs tarifs
              </div>
              <div>
                avec des <span>frais de dossier</span>{" "}
                <span style={{ fontSize: "28px" }}>gratuits</span>
              </div>
              <div>
                et, en cas de paiement annuel, une <span>remise de</span>{" "}
                <span style={{ fontSize: "28px" }}>-20%</span> sur le tarif
                initial 
                <sup>
                  <i
                    className="fa-solid fa-circle-info"
                    style={{ color: _Colors.purple, cursor: "pointer" }}
                    title="au lieu de la remise de -10% déjà en vigueur"
                  ></i>
                </sup>
              </div>
            </>
          ) : (
            <div>
              <span>Du 1er au 29 février</span>, profitez des meilleurs tarifs
              avec des <span>frais de dossier</span>{" "}
              <span style={{ fontSize: "28px" }}>gratuits</span> et, en cas de
              paiement annuel, une <span>remise de</span>{" "}
              <span style={{ fontSize: "28px" }}>-20%</span> sur le tarif
              initial 
              <sup>
                <i
                  className="fa-solid fa-circle-info"
                  title="au lieu de la remise de -10% déjà en vigueur"
                  style={{ color: _Colors.purple, cursor: "pointer" }}
                ></i>
              </sup>
            </div>
          )}
        </div>*/}
        {/**PROMO 
        <h2 className={classes.H2Promo} style={{ color: _Colors.purple }}>
          PROMOTION RAMADAN EXCEPTIONNELLE À SAISIR D'URGENCE !
        </h2>
        <div className={classes.DivPromoDouble}>
          <span role="img" aria-label="lune">
            &#x1F319;
          </span>{" "}
          <span>
            Du 1<sup>er</sup> au 8 avril, faites <u>encore plus d'économies</u>
             !
          </span>{" "}
          <span role="img" aria-label="lune">
            &#x1F319;
          </span>
          <br />
          <span>- 15%</span> sur le tarif initial
          <sup>
            <i
              className="fa-solid fa-circle-info"
              title="au lieu de la remise de -10% déjà en vigueur"
            ></i>
          </sup>{" "}
          en cas de paiement annuel
          <br />
          <span>- 10%</span> sur le tarif initial
          <sup>
            <i
              className="fa-solid fa-circle-info"
              title="au lieu de la remise de -5% déjà en vigueur"
            ></i>
          </sup>{" "}
          en cas de paiement trimestriel
        </div>

        <button className={classes.BtnPromoDouble} onClick={toggleFormulaire}>
          Je veux obtenir le code promo
        </button>

        <br />*/}
        <br />
        <PricingCards
          cursus="double"
          section={`double-cursus-Lycée`}
        ></PricingCards>

        {/** autres offres  */}
        <h2 className={classes.H2Offres}>
          CONSULTEZ NOS AUTRES CURSUS COMPLETS EN LIGNE
        </h2>
        <div className={classes.AutresOffres}>
          <a href="/cursus-classique/lycee">
            <img src={offreCC} alt="miniature offre cursus classique"></img>
          </a>
          <a href="/cursus-bilingue/lycee">
            <img src={offreCBI} alt="miniature offre cursus bilingue"></img>
          </a>
        </div>
      </div>
      {/** garanties  */}
      <div className={classes.Garanties}>
        <h2 className={classes.H2Garanties}>NOS GARANTIES</h2>

        <div>
          {appareil !== "PC" && (
            <img src={clipartGarantie} alt="clipart garantie blanc"></img>
          )}
          <div>
            <h3 className={classes.H3}>EDUQUER ENSEMBLE</h3>
            <p>
              EVE Éducation offre un accompagnement en ligne dédié et guide les
              familles dans l’éducation de leurs enfants pour une instruction
              familiale épanouissante.
              <br />
              Vous n’êtes plus seul !
            </p>
          </div>
          {appareil === "PC" && (
            <img src={clipartGarantie} alt="clipart garantie blanc"></img>
          )}
          <div>
            <h3 className={classes.H3}>VOTRE RÉUSSITE, NOTRE ENGAGEMENT</h3>
            <p>
              Votre objectif : viser la réussite
              <br />
              Notre engagement : vous donnez les moyens de réussir
            </p>
          </div>
        </div>
      </div>
      {/** 
      {modalEdt && (
        <div className={classes.ModalEdt} onClick={toggleModal}>
          <img src={edt} alt="emploi du temps primaire cursus classique"></img>
        </div>
      )}*/}
      {modalForm && (
        <FormCodePromo
          provenance={"Double cursus Lycée"}
          setModalForm={setModalForm}
        />
      )}
    </div>
  );
};

export default LyceeDouble2024;
