import React, { useState, useEffect, lazy, Suspense } from "react";
import { createUseStyles } from "react-jss";
import { Switch, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { TransitionGroup, CSSTransition } from "react-transition-group";
// import clientEmailTest from "./Subscription/emailContentTemplate/SubscriptionEmailContent"
// import adminEmailTest from "./Subscription/emailContentTemplate/NewRegisteredEmailContent"
import LoadingFallback from "./Utilitaires/LoadingFallback";
import { useRecoilValue } from "recoil";
import { routesState } from "../../recoil-atoms/webSiteAtoms";
import PrimaireDouble2024 from "./Programme/Double/PrimaireDouble2024";
import CollegeDouble2024 from "./Programme/Double/CollegeDouble2024";
import LyceeDouble2024 from "./Programme/Double/LyceeDouble2024";
import Article1 from "./Articles/Article1.js";
import Article2 from "./Articles/Article2.js";

//import des composants lazy
const Header = lazy(() => import("./Header/Header"));
const Accueil = lazy(() => import("./Accueil"));
const About = lazy(() => import("./About"));
const Offres = lazy(() => import("./Offres"));
const Recrutement = lazy(() => import("./Recrutement"));
const Actualites = lazy(() => import("./Actualites"));
const Prices = lazy(() => import("./Prices"));
const Maternelle = lazy(() => import("./Programme/Classic/Maternelle"));
//const Primaire = lazy(() => import("./Programme/Classic/Primaire"));
//const College = lazy(() => import("./Programme/Classic/College"));
const PrimaireClassique2024 = lazy(() =>
  import("./Programme/Classic/PrimaireClassique2024")
);
const CollegeClassique2024 = lazy(() =>
  import("./Programme/Classic/CollegeClassique2024")
);
const Lycee = lazy(() => import("./Programme/Classic/Lycee"));
const ProgrammeDouble = lazy(() => import("./Programme/Double"));
const PrimaireBilingue = lazy(() =>
  import("./Programme/Bilingue/PrimaireBilingue")
);
const CollegeBilingue = lazy(() =>
  import("./Programme/Bilingue/CollegeBilingue")
);
const LyceeBilingue = lazy(() => import("./Programme/Bilingue/LyceeBilingue"));
const PrimaireBelge = lazy(() => import("./Programme/Belge/PrimaireBelge"));
const ConfirmationEnvoi = lazy(() => import("./Programme/ConfirmationEnvoi"));
const ProgrammeAteliers = lazy(() => import("./Programme/Ateliers"));
const Contact = lazy(() => import("./Contact"));
const Faq = lazy(() => import("./Faq"));

//Nouveauté 2025
const CheckoutNew = lazy(() => import("./CheckoutNew"));
const Register = lazy(() => import("./Register"));
const PasswordPage = lazy(() =>
  import("./CheckoutNew/components/PasswordPage")
);
const UserSpace = lazy(() => import("./UserSpace"));
const CoursParticuliers = lazy(() =>
  import("./Programme/Carte/Content/ExclusifCarte.js")
);
const StagesEte = lazy(() => import("./Programme/Carte/Content/Stage.js"));
//fin nouveauté 2025
const Footer = lazy(() => import("./Footer"));

const ProgrammePec = lazy(() => import("./Programme/Pec"));
const CalendrierScolaire = lazy(() =>
  import("./Actualites/CalendrierScolaire")
);
const Temoignages = lazy(() => import("./Actualites/Temoignages"));
const LoginAdmin = lazy(() => import("./LoginAdmin"));
const Dashboard = lazy(() => import("./Dashboard"));
const MadrassaAuthentique = lazy(() =>
  import("./Programme/Carte/Content/MadrassaAuthentique")
);

//import ProgramsSchoolPacks from "./Accueil/components/ProgramsSchoolPacks";

const useStyles = createUseStyles({
  TransitionFading: {
    "& .fade-enter": {
      opacity: 0,
      zIndex: 1,
    },

    "& .fade-enter.fade-enter-active": {
      opacity: 1,
      transition: "opacity 250ms ease-in",
    },
  },
});

export const isProduction = process.env.NODE_ENV === "production";

function usePageViews() {
  const location = useLocation();
  //console.log("location", location, window.dataLayer);
  useEffect(() => {
    if (isProduction) {
      window.dataLayer.push({
        event: "pageview",
        page: location.pathname + location.search,
      });
    }
  }, [location]);
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebSiteApp = () => {
  //console.log("WebSiteApp");

  const classes = useStyles();
  let location = useLocation();
  const [showPolitics, setShowpolitics] = useState(false);
  const [showMentions, setShowMentions] = useState(false);
  const [showCGV, setShowCGV] = useState(false);
  const [showPersonnalData, setShowPersonnalData] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState(0);
  //variables relatives au code promo
  const [quelCodeActif, setQuelCodeActif] = useState("");
  const codesPromo = {
    /* A MODIFIER A LA BASE EN CAS DE CHANGEMENT DE PROMOS
    CODEEXCLU25: {
      message:
        "Jusqu'au 15 mars 2025, profitez : <ul><li>d'une remise de <b>-10%</b> sur le tarif initial en cas de paiement mensuel ou trimestriel</li><li>d'une remise de <b>-20%</b> sur le tarif initial en cas de paiement annuel (effectué <u>avant le 1<sup>er</sup> juillet 2025</u>)</li></ul>",
    },
    
     FIDELITE25: {
      message:
        "Privilèges réinscription cursus (1<sup>er</sup> au 15 fév.) : <ul><li><b>Zéro frais immédiats</b> : date limite de règlement le 15 juin 2025</li><li>Profitez de <b>-10%</b> sur le tarif initial ou de <b>-20%</b> en cas de paiement annuel</li></ul><small>Sous réserve d'avoir inscrit l'un de vos enfants à un cursus pour l'année scolaire 2024-2025.</br>Toute inscription a valeur d'engagement et les frais (139€ HT/cursus) devront être honorés en cas de rétractation.</small>",
    },
    ATANGLAIS2025: {
      message:
        "Remise - 20% sur l'atelier anglais - Code valable du 20 au 27 janvier 2025 inclus ",
    },
    STG24: {
      message: "Remise de 40 € - Code valable du 15 au 30 juin 2024 inclus",
    },
    
    CURSUSDB15: {
      message:
        "Remise sur le Double cursus (paiement trimestriel ou annuel) du 1er au 8 avril",
    },
    FRAISZERO: {
      //fee: false,
      message: "Frais de dossier gratuits (cursus 2024-2025)",
    },
    REDUC20CTA: {
      //discount: "year20",
      //fee: false,
      message:
        "Frais de dossier gratuits + Remise - 20 % sur le tarif initial, en lieu et place de la réduction de -10% actuellement appliquée, sous réserve du règlement intégral de la somme due avant avant le 1er juillet 2024.",
    },   
    DOSSIEROFF: {
      fee: false,
      message: "Frais de dossier gratuits (cursus 2024-2025)",
    },
    DOUBLE20: {
      message:
        "Remise - 20 % sur le tarif initial annuel du double cursus, hors option LVC, <u>sous réserve de règlement entier avant le 01/07/2024</u> ",
    },
    MADOFF: {
      message: "Frais de dossier gratuits des produits madrassa",
    },
    
    */
  };

  const routes = useRecoilValue(routesState);

  function isValidRoute(path) {
    if (routes.includes(path)) {
      return true;
    } else {
      return false;
    }
  }

  //INITIALISATION DES GOOGLES ANALYTICS + GOOGLE TAG MANAGER

  if (isProduction) {
    ReactGA.initialize("G-R5T2SXQMFJ", {
      debug: true,
      gaOptions: {
        siteSpeedSampleRate: 100,
      },
    });

    const tagManagerArgs = {
      gtmId: "GTM-K7V8JPCH",
    };

    TagManager.initialize(tagManagerArgs);
  }

  //

  useEffect(
    () => {
      // Suivre la page vue à chaque changement de route --Google Analytics
      if (isProduction) {
        ReactGA.send({
          hitType: "pageview",
          page: location.pathname,
        });
      }

      if (!isValidRoute(location.pathname)) {
        window.location.href = "/404";
      }
    },
    // eslint-disable-next-line
    [location]
  );

  usePageViews();

  return (
    <Suspense fallback={<LoadingFallback />}>
      {!location.pathname.includes("/admin") && (
        <Header
          selectedNavItem={selectedNavItem}
          codesPromo={codesPromo}
          quelCodeActif={quelCodeActif}
          setQuelCodeActif={setQuelCodeActif}
        />
      )}

      <TransitionGroup className={classes.TransitionFading}>
        <CSSTransition key={location.key} classNames="fade" timeout={400}>
          <Switch location={location}>
            {/* 
            <Route exact path="/email-admin" component={adminEmailTest} />
            <Route exact path="/email-client" component={clientEmailTest} /> */}
            {/* <Route exact path="/login" component={Login} />
            <Route exact path="/reset-mdp" component={ForgotPassword} />
            <Route exact path="/mdp-reset" component={PasswordReset} /> */}

            <Route exact path="/">
              <Accueil />
            </Route>
            <Route exact path="/cursus-classique/maternelle">
              <Maternelle setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/cursus-classique/primaire">
              {/*<Primaire setSelectedNavItem={setSelectedNavItem} />*/}
              <PrimaireClassique2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="CP"
              />
            </Route>
            <Route exact path="/cursus-classique/primaire/cp">
              {/*<Primaire setSelectedNavItem={setSelectedNavItem} niveau="CP" />*/}
              <PrimaireClassique2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="CP"
              />
            </Route>
            <Route exact path="/cursus-classique/primaire/ce1">
              {/*<Primaire setSelectedNavItem={setSelectedNavItem} niveau="CE1" />*/}
              <PrimaireClassique2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="CE1"
              />
            </Route>
            <Route exact path="/cursus-classique/primaire/ce2">
              {/*<Primaire setSelectedNavItem={setSelectedNavItem} niveau="CE2" />*/}
              <PrimaireClassique2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="CE2"
              />
            </Route>
            <Route exact path="/cursus-classique/primaire/cm1">
              {/*<Primaire setSelectedNavItem={setSelectedNavItem} niveau="CM1" />*/}
              <PrimaireClassique2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="CM1"
              />
            </Route>
            <Route exact path="/cursus-classique/primaire/cm2">
              {/*<Primaire setSelectedNavItem={setSelectedNavItem} niveau="CM2" />*/}
              <PrimaireClassique2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="CM2"
              />
            </Route>
            <Route exact path="/cursus-classique/college">
              {/*<College setSelectedNavItem={setSelectedNavItem} />*/}
              <CollegeClassique2024 setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/cursus-classique/college/sixieme">
              <CollegeClassique2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="Sixieme"
              />
            </Route>
            <Route exact path="/cursus-classique/college/cinquieme">
              <CollegeClassique2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="Cinquieme"
              />
            </Route>
            <Route exact path="/cursus-classique/college/quatrieme">
              <CollegeClassique2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="Quatrieme"
              />
            </Route>
            <Route exact path="/cursus-classique/college/troisieme">
              <CollegeClassique2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="Troisieme"
              />
            </Route>
            <Route exact path="/cursus-classique/lycee">
              <Lycee setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/cursus-classique/lycee/seconde">
              <Lycee setSelectedNavItem={setSelectedNavItem} niveau="Seconde" />
            </Route>
            <Route exact path="/cursus-classique/lycee/premiere">
              <Lycee
                setSelectedNavItem={setSelectedNavItem}
                niveau="Première"
              />
            </Route>
            <Route exact path="/cursus-classique/lycee/premiere/generale">
              <Lycee
                setSelectedNavItem={setSelectedNavItem}
                niveau="Première"
                filiere="Générale"
              />
            </Route>
            <Route exact path="/cursus-classique/lycee/premiere/stmg">
              <Lycee
                setSelectedNavItem={setSelectedNavItem}
                niveau="Première"
                filiere="STMG"
              />
            </Route>
            <Route exact path="/cursus-classique/lycee/premiere/st2s">
              <Lycee
                setSelectedNavItem={setSelectedNavItem}
                niveau="Première"
                filiere="ST2S"
              />
            </Route>

            <Route exact path="/cursus-classique/lycee/premiere/tronc-commun">
              <Lycee
                setSelectedNavItem={setSelectedNavItem}
                niveau="Première"
                filiere="TroncCommun"
              />
            </Route>
            <Route exact path="/cursus-classique/lycee/terminale">
              <Lycee
                setSelectedNavItem={setSelectedNavItem}
                niveau="Terminale"
              />
            </Route>
            <Route exact path="/cursus-classique/lycee/terminale/generale">
              <Lycee
                setSelectedNavItem={setSelectedNavItem}
                niveau="Terminale"
                filiere="Générale"
              />
            </Route>
            <Route exact path="/cursus-classique/lycee/terminale/stmg">
              <Lycee
                setSelectedNavItem={setSelectedNavItem}
                niveau="Terminale"
                filiere="STMG"
              />
            </Route>
            <Route exact path="/cursus-classique/lycee/terminale/st2s">
              <Lycee
                setSelectedNavItem={setSelectedNavItem}
                niveau="Terminale"
                filiere="ST2S"
              />
            </Route>
            <Route exact path="/cursus-classique/lycee/terminale/tronc-commun">
              <Lycee
                setSelectedNavItem={setSelectedNavItem}
                niveau="Terminale"
                filiere="TroncCommun"
              />
            </Route>
            <Route exact path="/double-cursus">
              {/*<ProgrammeDouble setSelectedNavItem={setSelectedNavItem} />*/}
              <PrimaireDouble2024 setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/double-cursus/primaire">
              {/* <ProgrammeDouble setSelectedNavItem={setSelectedNavItem} />*/}
              <PrimaireDouble2024 setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/double-cursus/primaire/cp">
              {/*  <ProgrammeDouble setSelectedNavItem={setSelectedNavItem} />*/}
              <PrimaireDouble2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="CP"
              />
            </Route>
            <Route exact path="/double-cursus/primaire/ce1">
              {/* <ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Primaire"
                filiere="CE1"
              />*/}
              <PrimaireDouble2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="CE1"
              />
            </Route>
            <Route exact path="/double-cursus/primaire/ce2">
              {/*<ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Primaire"
                filiere="CE2"
              />*/}
              <PrimaireDouble2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="CE2"
              />
            </Route>
            <Route exact path="/double-cursus/primaire/cm1">
              {/* <ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Primaire"
                filiere="CM1"
              />*/}
              <PrimaireDouble2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="CM1"
              />
            </Route>
            <Route exact path="/double-cursus/primaire/cm2">
              {/*<ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Primaire"
                filiere="CM2"
              />*/}
              <PrimaireDouble2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="CM2"
              />
            </Route>
            <Route exact path="/double-cursus/college">
              {/*<ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Collège"
                filiere="Sixième"
              />*/}
              <CollegeDouble2024 setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/double-cursus/college/sixieme">
              {/*<ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Collège"
                filiere="Sixième"
              />*/}
              <CollegeDouble2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="Sixième"
              />
            </Route>
            <Route exact path="/double-cursus/college/cinquieme">
              {/*<ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Collège"
                filiere="Cinquième"
              />*/}
              <CollegeDouble2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="Cinquième"
              />
            </Route>
            <Route exact path="/double-cursus/college/quatrieme">
              {/*<ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Collège"
                filiere="Quatrième"
              />*/}
              <CollegeDouble2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="Quatrième"
              />
            </Route>
            <Route exact path="/double-cursus/college/troisieme">
              {/*<ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Collège"
                filiere="Troisième"
              />*/}
              <CollegeDouble2024
                setSelectedNavItem={setSelectedNavItem}
                niveau="Troisième"
              />
            </Route>
            <Route exact path="/double-cursus/lycee">
              {/*<ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Lycée"
                filiere="Seconde"
              />*/}
              <LyceeDouble2024 setSelectedNavItem={setSelectedNavItem} />
            </Route>
            {/** 
            <Route exact path="/double-cursus/lycee/seconde">
              <ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Lycée"
                filiere="Seconde"
              />
          
            </Route>
            <Route exact path="/double-cursus/lycee/premiere/generale">
              <ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Lycée"
                filiere="Première Générale"
              />
            </Route>
            <Route exact path="/double-cursus/lycee/premiere/stmg">
              <ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Lycée"
                filiere="Première STMG"
              />
            </Route>
            <Route exact path="/double-cursus/lycee/premiere/st2s">
              <ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Lycée"
                filiere="Première ST2S"
              />
            </Route>
            
            <Route exact path="/double-cursus/lycee/terminale/generale">
              <ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Lycée"
                filiere="Terminale Générale"
              />
            </Route>
            <Route exact path="/double-cursus/lycee/terminale/stmg">
              <ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Lycée"
                filiere="Terminale STMG"
              />
            </Route>
            <Route exact path="/double-cursus/lycee/terminale/st2s">
              <ProgrammeDouble
                setSelectedNavItem={setSelectedNavItem}
                niveau="Lycée"
                filiere="Terminale ST2S"
              />
            </Route>*/}
            <Route exact path="/cursus-bilingue/primaire">
              <PrimaireBilingue setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/cursus-bilingue/college">
              <CollegeBilingue setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/cursus-bilingue/lycee">
              <LyceeBilingue setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/cursus-belge/primaire">
              <PrimaireBelge setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/confirmation-envoi">
              <ConfirmationEnvoi />
            </Route>
            <Route exact path="/cursus-pec">
              <ProgrammePec setSelectedNavItem={setSelectedNavItem} />
            </Route>

            <Route exact path="/cours-particuliers">
              <CoursParticuliers setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/stages-ete">
              <StagesEte setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/ateliers-recreatifs">
              <ProgrammeAteliers setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/la-madrassa-authentique">
              <MadrassaAuthentique setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/offres">
              <Offres setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/tarifs-cursus-classique">
              <Prices setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/tarifs-double-cursus">
              <Prices setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/tarifs-cursus-bilingue">
              <Prices setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/tarifs-cursus-belge">
              <Prices setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/tarifs-cursus-a-la-carte">
              <Prices setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/tarifs-ateliers-recreatifs">
              <Prices setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/tarifs-pec">
              <Prices setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/actualites">
              <Actualites setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/calendrier-scolaire">
              <CalendrierScolaire setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/temoignages">
              <Temoignages setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/faq">
              <Faq setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/qui-sommes-nous">
              <About setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/recrutement">
              <Recrutement setSelectedNavItem={setSelectedNavItem} />
            </Route>
            <Route exact path="/contact">
              <Contact setSelectedNavItem={setSelectedNavItem} />
            </Route>

            {/** Nouvelles routes 2025 */}

            <Route exact path="/checkout">
              <CheckoutNew
                quelCodeActif={quelCodeActif}
                setSelectedNavItem={setSelectedNavItem}
              />
            </Route>

            <Route exact path="/register">
              <Register />
            </Route>

            <Route path="/verification-email">
              <PasswordPage />
            </Route>

            <Route exact path="/mon-espace">
              <UserSpace />
            </Route>

            {/** Routes vers les articles ciblés */}
            <Route exact path="/ecole-musulmane-en-france">
              <Article1 />
            </Route>

            <Route exact path="/ecole-privee-musulmane-prix">
              {" "}
              <Article2 />
            </Route>

            {/** Routes plateforme administrative */}

            <Route exact path="/admin">
              <LoginAdmin />
            </Route>
            <Route exact path="/admin/dashboard">
              <Dashboard />
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      {!location.pathname.includes("/admin") && (
        <Footer
          showMentions={showMentions}
          setShowMentions={setShowMentions}
          showPolitics={showPolitics}
          setShowPolitics={setShowpolitics}
          showCGV={showCGV}
          setShowCGV={setShowCGV}
          showPersonnalData={showPersonnalData}
          setShowPersonnalData={setShowPersonnalData}
          showCredits={showCredits}
          setShowCredits={setShowCredits}
        />
      )}
    </Suspense>
  );
};

export default WebSiteApp;
