import React, { Fragment, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSetRecoilState } from "recoil";
import { cartProductsSelector } from "../../../../recoil-atoms/webSiteAtoms.js";
import { createUseStyles } from "react-jss";
import { _Colors, _BoxShadows } from "../../../../Jss-styles/_variables";
import ProductOptionForm from "./ProductOptionForm";
import EndModalButtons from "./EndModalButtons";
import LocalStorage from "../../../../assets/LocalStorage";
import { Modal, ModalHeader, ModalBody, Alert } from "reactstrap";
import { SelectOptionsInput } from "./ProductOptions/GenericSelect/SelectOptionsInput.js";
import { Form, Button } from "reactstrap";

const useStyles = createUseStyles({
  Modal: {
    maxWidth: "40rem",
    width: "100%",
    "& .modal-content": {
      boxShadow: _BoxShadows.large,
      borderStyle: "none !important",
    },
  },
  ModalHeader: {
    borderBottom: `1.5px solid ${_Colors.pink}`,
    borderSpacing: "1rem",
    "& span": {
      color: _Colors.darkPink,
      fontWeight: "bold",
    },
  },
  ModalBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  Alert: {
    backgroundColor: _Colors.veryLightTeal,
    color: _Colors.darkGreen,
    borderStyle: "none",
    padding: "1.2rem !important",
    margin: "1rem",
  },
  InfoBox: {
    border: "1px solid #3498db",
    backgroundColor: "#ecf0f1",
    padding: "10px",
    margin: "20px",
    borderRadius: "5px",
    fontWeight: "bold",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  InfoSymbol: {
    color: "#3498db",
    fontSize: "1.5em",
    marginRight: "5px",
  },
  Form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "2rem",

    "& .form-group": {
      width: "100%",
      maxWidth: "30rem",
      marginBottom: "1.5rem !important",
      "& .custom-select": {
        border: `2px solid ${_Colors.blue}`,
        borderRadius: "10px ",
        "&:focus": {
          borderColor: _Colors.pink,
          boxShadow: "none",
        },
      },
    },
    "& button": {
      backgroundColor: _Colors.purple,
      width: "50%",
      minWidth: "220px",
      marginTop: "2rem",
      transition: "all 200ms ease-in-out ",
      "&:hover": {
        transform: "scale(1.05)",
        boxShadow: _BoxShadows.medium,
        backgroundColor: _Colors.darkPink,
      },
    },
  },
  "@media (max-width: 390px)": {
    Form: {
      margin: "0px",
    },
  },
});

const ModalProductOption = ({ segment, toggle, openModal }) => {
  const classes = useStyles();

  const startStep = () => {
    //étape de départ de la modal
    //step1 : choix du produit (si plusieurs produits dans le segment)
    //step2 : choix des options du produit
    if (segment.length > 1) {
      return "step1";
    } else {
      return "step2";
    }
  };
  const firstProduct = segment[0];
  const [step, setStep] = useState(startStep());
  const [selectValue, setSelectValue] = useState("");
  const [product, setProduct] = useState(firstProduct);

  const setCartProductsState = useSetRecoilState(cartProductsSelector);

  const [addToBasketSuccess, setAddToBasketSuccess] = useState(false);

  let productId = uuidv4(); //for unique id of collapse product info and delete product on productsCart

  const addProductToList = (formData) => {
    let id = `${product.name}-00${productId}`; // donner un id unique à chaque produit mis dans le panier

    const price = () => {
      if (formData.paymentMethod !== "") {
        return product.price[formData.paymentMethod];
      } else {
        if (product.category === "stage") {
          if (formData.courseChoice.includes("2")) {
            return product.price.two;
          } else {
            return product.price.one;
          }
        }
      }
    };

    //console.log("paymentMeth", formData.paymentMethod);
    /*
    let price =
      formData.paymentMethod !== ""
        ? product.price[formData.paymentMethod]
        : product.category === "exclusif"
        ? product.price.hour
        : product.price.once;*/

    let productInfo = {
      id: id,
      name: product.name,
      cursus: product.cursus,
      category: product.category,
      hours: product.hours,
      price: price(),
      formData,
    };

    //console.log(productInfo);

    setCartProductsState(productInfo); //set recoil state

    let storage = new LocalStorage();
    storage.addProductsToProductsCartStorage(productInfo); //set localstorage

    setAddToBasketSuccess(true);
  };

  const handeClick = (e) => {
    e.preventDefault();
    setAddToBasketSuccess(false);
    toggle();
  };

  const handleSelect = (e) => {
    let value = e.target.value;
    value !== "" && (e.target.style.borderColor = "#fe77c3");
    setSelectValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProduct(segment.filter((produit) => produit.name === selectValue)[0]);
    setStep("step2");
  };

  useEffect(() => {
    //à chaque fois que la modale se ferme, se remettre en étape de départ
    //s'assurer que addToBasketSuccess ne s'affiche pas si on sortait d'une validation
    //remettre à zéro la valeur dans le champ select
    if (!openModal) {
      setStep(startStep());
      setAddToBasketSuccess(false);
      setSelectValue("");
    }
    // eslint-disable-next-line
  }, [openModal]);

  return (
    <Modal
      centered
      isOpen={openModal}
      toggle={toggle}
      className={classes.Modal}
    >
      <ModalHeader className={classes.ModalHeader} toggle={toggle}>
        <>
          {step === "step1" && <>J'inscris mon enfant</>}
          {step === "step2" && (
            <>
              Inscription{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: product.abrev ? product.abrev : product.name,
                }}
              ></span>
            </>
          )}
        </>
      </ModalHeader>
      <ModalBody
        className={addToBasketSuccess === true ? classes.ModalBody : ""}
      >
        {addToBasketSuccess && (
          <Fragment>
            <Alert className={classes.Alert}>
              <h3>
                {product.cursus !== "pec"
                  ? "L'inscription a été ajoutée à votre liste"
                  : "Le pack a été ajouté à votre liste"}
              </h3>
              <p>
                Vous pouvez accéder à votre liste afin de terminer votre/vos
                inscription(s) ou, si vous le souhaitez, inscrire d’autres
                enfants.
              </p>
            </Alert>
            <EndModalButtons toggle={handeClick} />
          </Fragment>
        )}
        {!addToBasketSuccess && step === "step1" && (
          <Form onSubmit={handleSubmit} className={classes.Form}>
            <SelectOptionsInput
              name="product"
              value={selectValue}
              handleSelect={handleSelect}
            >
              <option key={"option-0"} value="">
                Choisir le niveau désiré
              </option>
              {segment.map((produit, k) => {
                return (
                  <option
                    key={`option-${k}`}
                    value={produit.name}
                    disabled={!produit.stock.isOpen}
                  >
                    {produit.name}
                    {!produit.stock.isOpen && " (complet)"}
                  </option>
                );
              })}
            </SelectOptionsInput>
            <Button>Continuer</Button>
          </Form>
        )}
        {!addToBasketSuccess && step === "step2" && (
          <>
            <ProductOptionForm
              product={product}
              addProductToList={addProductToList}
            />

            {/** POUR LES CLASSES LVB/LVC 
            {(product.cycle === "Cycle 4" || product.cycle === "Lycée") && (
              <small style={{ display: "block", marginInline: "20px" }}>
                * Si le niveau de l’élève est jugé insuffisant en langue
                vivante, il reviendra à la famille d’assurer un renforcement des
                acquis nécessaire afin de pallier les difficultés éventuelles
                dans le suivi des cours.
              </small>
            )}*/}
            {/**POUR LES CLASSES FILIERES */}
            {product.branches !== undefined &&
              product.name !== "Maternelle" &&
              product.category !== "tronc" && (
                <small
                  style={{
                    display: "block",
                    marginInline: "20px",
                    marginTop: "10px",
                  }}
                >
                  ** La dispense d’une spécialité dépend de plusieurs critères,
                  entre autres du nombre d’inscrits. De ce fait, le Client est
                  informé que les spécialités sélectionnées ne sont pas
                  obligatoirement celles qui seront enseignées au sein de
                  l'établissement.
                </small>
              )}
            {/** POUR LES TROISIEMES UNIQUEMENT */}
            {product.name === "3eme" && (
              <div className={classes.InfoBox}>
                <span>
                  <i
                    className={`fas fa-question-circle ${classes.InfoSymbol}`}
                  ></i>{" "}
                  L'inscription à l'examen du brevet est <u>obligatoire</u> pour
                  un passage ultérieur au lycée.
                </span>
              </div>
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalProductOption;
