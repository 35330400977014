//MATERNELLE
import GS_A_Img from "../../../../assets/img/emplois-du-temps/GS_A_2021.webp";
import GS_B_Img from "../../../../assets/img/emplois-du-temps/GS_B_2021.webp";

//PRIMAIRE
import CP_CC_M_Img from "../../../../assets/img/emplois-du-temps/primaire/CP CC M.webp";
import CP_CC_AM_Img from "../../../../assets/img/emplois-du-temps/primaire/CP CC AM.webp";
import CP_DC_8h_Img from "../../../../assets/img/emplois-du-temps/primaire/CP-8h-DC.webp";
import CP_DC_9h_Img from "../../../../assets/img/emplois-du-temps/primaire/CP-9h-DC.webp";

import CE1_CC_M_Img from "../../../../assets/img/emplois-du-temps/primaire/CE1 CC M.webp";
import CE1_CC_AM_Img from "../../../../assets/img/emplois-du-temps/primaire/CE1 CC AM.webp";
import CE1_DC_8h_Img from "../../../../assets/img/emplois-du-temps/primaire/CE1-8h-DC.webp";
import CE1_DC_9h_Img from "../../../../assets/img/emplois-du-temps/primaire/CE1-9h-DC.webp";

import CE2_CC_M_Img from "../../../../assets/img/emplois-du-temps/primaire/CE2 CC M.webp";
import CE2_CC_AM_Img from "../../../../assets/img/emplois-du-temps/primaire/CE2 CC AM.webp";
import CE2_DC_Img from "../../../../assets/img/emplois-du-temps/primaire/CE2 DC.webp";

import CM1_CC_M_Img from "../../../../assets/img/emplois-du-temps/primaire/CM1 CC M.webp";
import CM1_CC_AM_Img from "../../../../assets/img/emplois-du-temps/primaire/CM1 CC AM.webp";
import CM1_DC_Img from "../../../../assets/img/emplois-du-temps/primaire/CM1 DC.webp";

import CM2_CC_M_Img from "../../../../assets/img/emplois-du-temps/primaire/CM2 CC M.webp";
import CM2_CC_AM_Img from "../../../../assets/img/emplois-du-temps/primaire/CM2 CC AM.webp";
import CM2_DC_Img from "../../../../assets/img/emplois-du-temps/primaire/CM2 DC.webp";

import Premiere_Annee_8h from "../../../../assets/img/emplois-du-temps/belge/edt-premiere-annee-8h.jpg";
import Premiere_Annee_10h30 from "../../../../assets/img/emplois-du-temps/belge/edt-premiere-annee-10h30.jpg";
import Deuxieme_Annee_8h from "../../../../assets/img/emplois-du-temps/belge/edt-deuxieme-annee-8h.jpg";
import Deuxieme_Annee_10h30 from "../../../../assets/img/emplois-du-temps/belge/edt-deuxieme-annee-10h30.jpg";
import Troisieme_Annee_8h from "../../../../assets/img/emplois-du-temps/belge/edt-troisieme-annee-8h.jpg";
import Troisieme_Annee_10h30 from "../../../../assets/img/emplois-du-temps/belge/edt-troisieme-annee-10h30.jpg";
import Quatrieme_Annee_8h from "../../../../assets/img/emplois-du-temps/belge/edt-quatrieme-annee-8h.jpg";
import Quatrieme_Annee_10h30 from "../../../../assets/img/emplois-du-temps/belge/edt-quatrieme-annee-10h30.jpg";
import Cinquieme_Annee_8h from "../../../../assets/img/emplois-du-temps/belge/edt-cinquieme-annee-8h.jpg";
import Cinquieme_Annee_10h30 from "../../../../assets/img/emplois-du-temps/belge/edt-cinquieme-annee-10h30.jpg";
import Sixieme_Annee_8h from "../../../../assets/img/emplois-du-temps/belge/edt-sixieme-annee-8h.jpg";
import Sixieme_Annee_10h30 from "../../../../assets/img/emplois-du-temps/belge/edt-sixieme-annee-10h30.jpg";

//COLLEGE
import S6_CC_M_Img from "../../../../assets/img/emplois-du-temps/collège/6EME CC M.webp";
import S6_CC_AM_Img from "../../../../assets/img/emplois-du-temps/collège/6EME CC AM.webp";
import S6_DC_Img from "../../../../assets/img/emplois-du-temps/collège/6EME DC.webp";

import S5_CC_M_Img from "../../../../assets/img/emplois-du-temps/collège/5EME CC M.webp";
import S5_CC_AM_Img from "../../../../assets/img/emplois-du-temps/collège/5EME CC AM.webp";
import S5_DC_Img from "../../../../assets/img/emplois-du-temps/collège/5EME DC.webp";

import S4_CC_M_Img from "../../../../assets/img/emplois-du-temps/collège/4EME CC M.webp";
import S4_CC_AM_Img from "../../../../assets/img/emplois-du-temps/collège/4EME CC AM.webp";
import S4_DC_Img from "../../../../assets/img/emplois-du-temps/collège/4EME DC.webp";

import S3_CC_M_Img from "../../../../assets/img/emplois-du-temps/collège/3EME CC M.webp";
import S3_CC_AM_Img from "../../../../assets/img/emplois-du-temps/collège/3EME CC AM.webp";
import S3_DC_Img from "../../../../assets/img/emplois-du-temps/collège/3EME DC.webp";

//LYCEE
import SE_CC_Img from "../../../../assets/img/emplois-du-temps/lycée/classique/SECONDE-CC.webp";
import SE_DC_Img from "../../../../assets/img/emplois-du-temps/lycée/double/SECONDE-DC.webp";

import L1_G_CC_Img from "../../../../assets/img/emplois-du-temps/lycée/classique/1ERE-CC-GENERALE.webp";
import L1_STMG_CC_Img from "../../../../assets/img/emplois-du-temps/lycée/classique/1ERE-CC-STMG.webp";
import L1_ST2S_CC_Img from "../../../../assets/img/emplois-du-temps/lycée/classique/1ERE-CC-ST2S.webp";
//import L1_STI2D_CC_Img from "../../../../assets/img/emplois-du-temps/lycée/classique/1ERE-CC-STI2D.jpeg";
import L1_TC_Img from "../../../../assets/img/emplois-du-temps/lycée/classique/1ERE-CC-TC.webp";
import L1_G_DC_Img from "../../../../assets/img/emplois-du-temps/lycée/double/1ERE-DC-GENERALE.webp";
import L1_STMG_DC_Img from "../../../../assets/img/emplois-du-temps/lycée/double/1ERE-DC-STMG.webp";
import L1_ST2S_DC_Img from "../../../../assets/img/emplois-du-temps/lycée/double/1ERE-DC-ST2S.webp";
//import L1_STI2D_DC_Img from "../../../../assets/img/emplois-du-temps/lycée/double/1ERE-DC-STI2D.webp";

import T_G_C_Img from "../../../../assets/img/emplois-du-temps/lycée/classique/TLE-CC-GENERALE.webp";
import T_STMG_CC_Img from "../../../../assets/img/emplois-du-temps/lycée/classique/TLE-CC-STMG.webp";
import T_ST2S_CC_Img from "../../../../assets/img/emplois-du-temps/lycée/classique/TLE-CC-ST2S.webp";
import T_TC_Img from "../../../../assets/img/emplois-du-temps/lycée/classique/TLE-CC-TC.webp";
import T_G_DC_Img from "../../../../assets/img/emplois-du-temps/lycée/double/TLE-DC-GENERALE.webp";
import T_STMG_DC_Img from "../../../../assets/img/emplois-du-temps/lycée/double/TLE-DC-STMG.webp";
import T_ST2S_DC_Img from "../../../../assets/img/emplois-du-temps/lycée/double/TLE-DC-ST2S.webp";

//MADRASSA
import edt from "../../../../assets/img/emploi_du_temps.png";
import edtAC2H from "../../../../assets/img/emplois-du-temps/madrassa/edt-madrassa-AC2H.jpg";
import edtAC4H from "../../../../assets/img/emplois-du-temps/madrassa/edt-madrassa-AC4H.jpg";
import edtC from "../../../../assets/img/emplois-du-temps/madrassa/edt-madrassa-C.jpg";

//import non_dispo from "../../../../assets/img/emplois-du-temps/coming_soon.jpeg";

// rassemble les emplois du temps qui s'affichent dans les modales pour chaque section
export const schedulesConfig = {
  GS: {
    button: GS_A_Img,
    buttonWidth: {
      lg: "661px",
    },
    alt: "emploi du temps maternelle",
    title: "Emploi du temps Maternelle MS / GS",
    image: [GS_A_Img, GS_B_Img],
  },
  CP: {
    button: CP_CC_M_Img,
    alt: "emploi du temps CP",
    title: "Emploi du temps CP (matin ou après-midi)",
    image: [CP_CC_M_Img, CP_CC_AM_Img],
  },
  CE1: {
    button: CE1_CC_M_Img,
    alt: "emploi du temps CE1",
    title: "Emploi du temps CE1 (matin ou après-midi)",
    image: [CE1_CC_M_Img, CE1_CC_AM_Img],
  },
  CE2: {
    button: CE2_CC_M_Img,
    alt: "emploi du temps CE2",
    title: "Emploi du temps CE2 (matin ou après-midi)",
    image: [CE2_CC_M_Img, CE2_CC_AM_Img],
  },
  CM1: {
    button: CM1_CC_M_Img,
    alt: "emploi du temps CM1",
    title: "Emploi du temps CM1 (matin ou après-midi)",
    image: [CM1_CC_M_Img, CM1_CC_AM_Img],
  },
  CM2: {
    button: CM2_CC_M_Img,
    alt: "emploi du temps CM2",
    title: "Emploi du temps CM2 (matin ou après-midi)",
    image: [CM2_CC_M_Img, CM2_CC_AM_Img],
  },
  Sixieme: {
    button: S6_CC_M_Img,
    alt: "emploi du temps sixième",
    title: "Emploi du temps 6<sup>e</sup> (matin ou après-midi)",
    image: [S6_CC_M_Img, S6_CC_AM_Img],
  },
  Cinquieme: {
    button: S5_CC_M_Img,
    alt: "emploi du temps cinquième",
    title: "Emploi du temps 5<sup>e</sup> (matin ou après-midi)",
    image: [S5_CC_M_Img, S5_CC_AM_Img],
  },
  Quatrieme: {
    button: S4_CC_M_Img,
    alt: "emploi du temps quatrième",
    title: "Emploi du temps 4<sup>e</sup> (matin ou après-midi)",
    image: [S4_CC_M_Img, S4_CC_AM_Img],
  },
  Troisieme: {
    button: S3_CC_M_Img,
    alt: "emploi du temps troisième",
    title: "Emploi du temps 3<sup>e</sup> (matin ou après-midi)",
    image: [S3_CC_M_Img, S3_CC_AM_Img],
  },
  Seconde: {
    button: SE_CC_Img,
    alt: "emploi du temps seconde",
    title: "Emploi du temps 2<sup>de</sup>",
    image: [SE_CC_Img],
  },
  Première: {
    Générale: {
      button: L1_G_CC_Img,
      alt: "emploi du temps première générale",
      title: "Emploi du temps 1<sup>re</sup> Générale",
      image: [L1_G_CC_Img],
    },
    STMG: {
      button: L1_STMG_CC_Img,
      alt: "emploi du temps première stmg",
      title: "Emploi du temps 1<sup>re</sup> STMG",
      image: [L1_STMG_CC_Img],
    },
    ST2S: {
      button: L1_ST2S_CC_Img,
      alt: "emploi du temps première st2s",
      title: "Emploi du temps 1<sup>re</sup> ST2S",
      image: [L1_ST2S_CC_Img],
    } /*
    STI2D: {
      button: L1_STI2D_CC_Img,
      alt: "emploi du temps première sti2d",
      title: "Emploi du temps 1<sup>re</sup> STI2D",
      image: [L1_STI2D_CC_Img],
    },*/,
    TroncCommun: {
      button: L1_TC_Img,
      alt: "emploi du temps première tronc commun",
      title: "Emploi du temps 1<sup>re</sup> Tronc Commun",
      image: [L1_TC_Img],
    },
  },
  Terminale: {
    Générale: {
      button: T_G_C_Img,
      alt: "emploi du temps terminale générale",
      title: "Emploi du temps T<sup>le</sup> Générale",
      image: [T_G_C_Img],
    },
    STMG: {
      button: T_STMG_CC_Img,
      alt: "emploi du temps terminale stmg",
      title: "Emploi du temps T<sup>le</sup> STMG",
      image: [T_STMG_CC_Img],
    },
    ST2S: {
      button: T_ST2S_CC_Img,
      alt: "emploi du temps terminale st2s",
      title: "Emploi du temps T<sup>le</sup> ST2S",
      image: [T_ST2S_CC_Img],
    },
    TroncCommun: {
      button: T_TC_Img,
      alt: "emploi du temps terminale tronc commun",
      title: "Emploi du temps T<sup>le</sup> Tronc Commun",
      image: [T_TC_Img],
    },
  },

  primaryDC: {
    CP: {
      button: CP_DC_8h_Img,
      alt: "emploi du temps cp cursus bilingue international",
      title: "Emploi du temps CP",
      image: [CP_DC_8h_Img, CP_DC_9h_Img],
    },
    CE1: {
      button: CE1_DC_8h_Img,
      alt: "emploi du temps ce1 cursus bilingue international",
      title: "Emploi du temps CE1",
      image: [CE1_DC_8h_Img, CE1_DC_9h_Img],
    },
    CE2: {
      button: CE2_DC_Img,
      alt: "emploi du temps ce2 cursus bilingue international",
      title: "Emploi du temps CE2",
      image: [CE2_DC_Img],
    },
    CM1: {
      button: CM1_DC_Img,
      alt: "emploi du temps cm1 cursus bilingue international",
      title: "Emploi du temps CM1",
      image: [CM1_DC_Img],
    },
    CM2: {
      button: CM2_DC_Img,
      alt: "emploi du temps cm2 cursus bilingue international",
      title: "Emploi du temps CM2",
      image: [CM2_DC_Img],
    },
  },

  primaryBE: {
    P1: {
      button: Premiere_Annee_8h,
      alt: "emploi du temps 1re année cursus belge",
      title: "Emploi du temps - Première année",
      image: [Premiere_Annee_8h],
    },
    P2: {
      button: Deuxieme_Annee_8h,
      alt: "emploi du temps 2e année cursus belge",
      title: "Emploi du temps - Deuxième année",
      image: [Deuxieme_Annee_8h],
    },
    P3: {
      button: Troisieme_Annee_8h,
      alt: "emploi du temps 3e année cursus belge",
      title: "Emploi du temps - Troisième année",
      image: [Troisieme_Annee_8h],
    },
    P4: {
      button: Quatrieme_Annee_8h,
      alt: "emploi du temps 4e année cursus belge",
      title: "Emploi du temps - Quatrième année",
      image: [Quatrieme_Annee_8h],
    },
    P5: {
      button: Cinquieme_Annee_8h,
      alt: "emploi du temps 5e année cursus belge",
      title: "Emploi du temps - Cinquième année",
      image: [Cinquieme_Annee_8h],
    },
    P6: {
      button: Sixieme_Annee_8h,
      alt: "emploi du temps 6e année cursus belge",
      title: "Emploi du temps - Sixième année",
      image: [Sixieme_Annee_8h],
    },
  },

  primaryBE_OC: {
    P1: {
      button: Premiere_Annee_10h30,
      alt: "emploi du temps 1re année cursus belge",
      title: "Emploi du temps - Première année",
      image: [Premiere_Annee_10h30],
    },
    P2: {
      button: Deuxieme_Annee_10h30,
      alt: "emploi du temps 2e année cursus belge",
      title: "Emploi du temps - Deuxième année",
      image: [Deuxieme_Annee_10h30],
    },
    P3: {
      button: Troisieme_Annee_10h30,
      alt: "emploi du temps 3e année cursus belge",
      title: "Emploi du temps - Troisième année",
      image: [Troisieme_Annee_10h30],
    },
    P4: {
      button: Quatrieme_Annee_10h30,
      alt: "emploi du temps 4e année cursus belge",
      title: "Emploi du temps - Quatrième année",
      image: [Quatrieme_Annee_10h30],
    },
    P5: {
      button: Cinquieme_Annee_10h30,
      alt: "emploi du temps 5e année cursus belge",
      title: "Emploi du temps - Cinquième année",
      image: [Cinquieme_Annee_10h30],
    },
    P6: {
      button: Sixieme_Annee_10h30,
      alt: "emploi du temps 6e année cursus belge",
      title: "Emploi du temps - Sixième année",
      image: [Sixieme_Annee_10h30],
    },
  },

  secondaryDC: {
    Sixieme: {
      button: S6_DC_Img,
      alt: "emploi du temps sixième 6e cursus bilingue international",
      title: "Emploi du temps 6EME",
      image: [S6_DC_Img],
    },
    Cinquieme: {
      button: S5_DC_Img,
      alt: "emploi du temps sixième 6e cursus international",
      title: "Emploi du temps 5EME",
      image: [S5_DC_Img],
    },
    Quatrieme: {
      button: S4_DC_Img,
      alt: "emploi du temps sixième 6e cursus bilingue international",
      title: "Emploi du temps 4EME",
      image: [S4_DC_Img],
    },
    Troisieme: {
      button: S3_DC_Img,
      alt: "emploi du temps sixième 6e cursus bilingue international",
      title: "Emploi du temps 3EME",
      image: [S3_DC_Img],
    },
  },

  highDC: {
    Seconde: {
      button: SE_DC_Img,
      alt: "emploi du temps seconde cursus bilingue international",
      title: "Emploi du temps Seconde",
      image: [SE_DC_Img],
    },
    PremiereG: {
      button: L1_G_DC_Img,
      alt: "emploi du temps première générale cursus bilingue international",
      title: "Emploi du temps 1ERE Générale",
      image: [L1_G_DC_Img],
    },
    PremiereSTMG: {
      button: L1_STMG_DC_Img,
      alt: "emploi du temps première STMG cursus bilingue international",
      title: "Emploi du temps 1ERE STMG",
      image: [L1_STMG_DC_Img],
    },
    PremiereST2S: {
      button: L1_ST2S_DC_Img,
      alt: "emploi du temps première ST2S cursus bilingue international",
      title: "Emploi du temps 1ERE ST2S",
      image: [L1_ST2S_DC_Img],
    },
    /*PremiereSTI2D: {
      button: L1_STI2D_DC_Img,
      alt: "emploi du temps première STI2D cursus bilingue international",
      title: "Emploi du temps 1ERE STI2D",
      image: [L1_STI2D_DC_Img],
    },*/
    TerminaleG: {
      button: T_G_DC_Img,
      alt: "emploi du temps terminale générale cursus bilingue international",
      title: "Emploi du temps Terminale Générale",
      image: [T_G_DC_Img],
    },
    TerminaleSTMG: {
      button: T_STMG_DC_Img,
      alt: "emploi du temps terminale STMG cursus bilingue international",
      title: "Emploi du temps Terminale STMG",
      image: [T_STMG_DC_Img],
    },
    TerminaleST2S: {
      button: T_ST2S_DC_Img,
      alt: "emploi du temps terminale ST2S cursus bilingue international",
      title: "Emploi du temps Terminale ST2S",
      image: [T_ST2S_DC_Img],
    },
  },
  FormuleArabe: {
    button: edt,
    alt: "emploi du temps cours d'arabe",
    title: "Emploi du temps ARABE/CORAN",
    image: [edtAC2H, edtAC4H],
  },
  FormuleCoran: {
    button: edt,
    alt: "emploi du temps cours de Coran",
    title: "Emploi du temps ARABE/CORAN",
    image: [edtAC2H, edtAC4H],
  },
  FormuleComplete: {
    button: edt,
    alt: "emploi du temps formule complète",
    title: "Emploi du temps Formule Complète",
    image: [edtC],
  },

  /*onCartArab: [
    {
      title: "Emploi du temps Pôle Arabe",
      image: [PoleAr_Img_1, PoleAr_Img_2],
    },
  ],*/
};
