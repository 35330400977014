import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { countryState } from "../../recoil-atoms/webSiteAtoms";
import { useSetRecoilState } from "recoil";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const setCountry = useSetRecoilState(countryState);

  // Vérifie la présence du token dans les cookies au montage
  useEffect(() => {
    const token = Cookies.get("auth_token");
    setIsAuthenticated(!!token); // true si le token existe, sinon false
  }, []);

  const login = async (token) => {
    Cookies.set("auth_token", token, {
      secure: true,
      sameSite: "Strict",
      expires: 2 / 24, // = 2 heures
    });
    setIsAuthenticated(true);
    const getCountry = await axios
      .get(`${process.env.REACT_APP_API_URL}get/country`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => ({ country: res.data.country }))
      .catch((err) => ({ error: err.message }));
    const country = getCountry.country;
    if (country) {
      setCountry(country);
    }
  };

  const logout = () => {
    Cookies.remove("auth_token");
    setIsAuthenticated(false);
    setCountry("France");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte
export const useAuth = () => useContext(AuthContext);
