import { Notyf } from "notyf";
import { isProduction } from "../../../recoil-atoms/webSiteAtoms";

export const loginConfig = {
  method: "post",
  url: `${process.env.REACT_APP_API_URL}login_check`,
  data: {
    username: process.env.REACT_APP_EVE_EDUCATION_API_USERNAME,
    password: process.env.REACT_APP_EVE_EDUCATION_API_PASSWORD,
  },
  headers: { "Content-Type": "application/json" },
};

export const notyf = new Notyf({
  position: {
    x: "center",
    y: "bottom",
  },
  duration: 0, //illimitée
  dismissible: true,
});

///// ----------------DONNEES PAR DEFAUT DU FORMULAIRE ////////////////////////////////////////

export const registerDefault = {
  //12 champs lg1
  status: isProduction ? "" : "father",
  lastname: isProduction ? "" : "NOMpère",
  firstname: isProduction ? "" : "Prénompère",
  nationality: isProduction ? "" : "Française",
  profession: isProduction ? "" : "Développeur",
  country: isProduction ? "" : "France",
  city: isProduction ? "" : "Paris",
  address: isProduction ? "" : "rue des Champs Élysées",
  email: isProduction ? "" : "eve.education.dev@gmail.com",
  phone: isProduction ? "" : "+336",
  whatsapp: isProduction ? "" : "+336",
  facebook: isProduction ? "" : "Un profil",
  // 12 champs lg2
  secondStatus: isProduction ? "" : "mother",
  secondLastname: isProduction ? "" : "NOMmère",
  secondFirstname: isProduction ? "" : "Prénommère",
  secondNationality: isProduction ? "" : "Allemande",
  secondProfession: isProduction ? "" : "Boulangère",
  secondCountry: isProduction ? "" : "Allemagne",
  secondCity: isProduction ? "" : "Berlin",
  secondAddress: isProduction ? "" : "rue de Hambourg",
  secondEmail: isProduction ? "" : "eve.education.dev@gmail.com",
  secondPhone: isProduction ? "" : "+337",
  secondWhatsapp: isProduction ? "" : "+337",
  secondFacebook: isProduction ? "" : "Un autre profil",
  //enfants (1 enfant = 1 objet)
  children: [
    {
      lastname: isProduction ? "" : "NOM enfant",
      firstname: isProduction ? "" : "Prénom enfant",
      gender: isProduction ? "" : "M",
      dateOfBirth: isProduction ? "" : "2010-05-14",
      placeOfBirth: isProduction ? "" : "Paris",
      nationality: isProduction ? "" : "Française",
      residencePlace: isProduction ? "" : "lg1",
      address: isProduction ? "" : "",
      city: isProduction ? "" : "",
      country: isProduction ? "" : "",
      grade: isProduction ? "" : "CM2",
      IEFClasses: isProduction ? "" : "",
      liveClasses: isProduction ? "" : "",
      oldSchools: isProduction ? "" : "",
      disorder: isProduction ? "" : "non",
      otherInfo: isProduction ? "" : "",
    },
  ],
};

//////////------------FONCTIONS DE VALIDATION ////////////////////////////////////////////////////////////////////////////////////////

export const verificationEmail = (email) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const verificationNomPrenom = (nomPrenom) => {
  //un nom ou prénom ne contient que des lettres fr et < 50 caractères (bdd)
  const regex = /^[a-zA-Zà-ÿÀ-ß-]+([\s-][a-zA-Zà-ÿÀ-ß-]+)*$/; // Lettres, y compris accentuées, espaces et tirets
  return nomPrenom.length <= 50 && regex.test(nomPrenom);
};

export const validationLongueurMax = (valeur, longueurMax) => {
  return valeur.length <= longueurMax;
};

export const validationSelect = (valeur, propositions) => {
  return propositions.includes(valeur);
};

export const validationRequired = (valeur) => {
  return valeur.trim() !== "";
};

export const validationTelephone = (telephone) => {
  const phoneRegex = /^[\d\s+()\-]+$/; // Regex pour vérifier les chiffres, espaces, et +
  return phoneRegex.test(telephone);
};

export const validationConfirmation = (valeur1, valeur2) => {
  return valeur1 === valeur2;
};

export const validationDate = (dateString) => {
  // Validation 1 - Vérifie si la chaîne correspond au format aaaa-mm-dd
  const regex = /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  if (!dateString.match(regex)) {
    return false;
  }

  // Validation 2 - Vérifier si c'est une date valide (par exemple pas de 30/02)
  const parts = dateString.split("-");
  const day = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10) - 1; // Les mois sont indexés à partir de 0 en JS
  const year = parseInt(parts[0], 10);

  // Crée un objet Date avec les parties de la date
  //si c'était une date invalide date retourne alors la vraie date la plus proche
  const date = new Date(year, month, day);

  // Vérifie si la date est valide
  return (
    date.getFullYear() === year &&
    date.getMonth() === month &&
    date.getDate() === day
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const openWA = () => {
  //ouvrir le WA situé dans le footer

  const wa = document.querySelector(
    "div[class*=styles-module_whatsappButton__IWx9V]"
  );
  wa.click();
};

export const format = (width) => {
  if (width >= 1366) {
    return "PC";
  } else if (width > 600) {
    return "Tablette";
  } else {
    return "Mobile";
  }
};

export const responsiveWidth = (width) => {
  return {
    video: {
      PC: 656,
      Tablette: width * 0.9,
      Mobile: width * 0.9,
    },
  };
};

export const responsiveHeight = (width) => {
  return {
    video: {
      PC: 369,
      Tablette: (width * 0.9 * 9) / 16,
      Mobile: (width * 0.9 * 9) / 16,
    },
  };
};
